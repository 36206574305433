import React from 'react';

import './Main.css';

// Presentational Components
import QueueTab from './QueueTab';
import ModifyGuestSection from './ModifyGuestSection';
import MainHeader from './MainHeader';

// Redux Actions
import { connect } from 'react-redux';
import { getSendPositionUpdates } from '../../Redux/Actions/showActions';
import { getShowGuests } from '../../Redux/Actions/guestActions';
import { getLTAUsers } from '../../Redux/Actions/loginActions';
import { updateCurrentTime } from '../../Redux/Actions/userinterfaceActions';

// Logging

function mapStateToProps(store) {
	return ({
		user: store.user,
		show: {
			currentShow: store.show.currentShow
		}
	});
}

class Main extends React.Component {

	componentDidMount() {
		function setTimeInOneSecond(props) {
			setTimeout(() => {
				props.dispatch(updateCurrentTime());
				setTimeInOneSecond(props);
			}, 1000);
		}
		setTimeInOneSecond(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props === nextProps) {
			return;
		}
		if (!this.props.user.LTAUsers) {
			this.props.dispatch(getLTAUsers());
		}
		if (nextProps.show.currentShow !== this.props.show.currentShow) {
			if (nextProps.show.currentShow && nextProps.show.currentShow.ShowId) {
				this.props.dispatch(getShowGuests(nextProps.show.currentShow.ShowId));
				this.props.dispatch(getSendPositionUpdates(nextProps.show.currentShow.ShowId));
			}
		}
	}

	render() {
		return (
			<div className='Main NoiseBackground'>
				<MainHeader/>
				<div className='Body'>
					<div className='CenterQueue'>
						<QueueTab queues={[[['Incoming'], 'Inbound'], [['Blocked'], 'Blocked']]}/>
					</div>
					<div className='CenterModifyGuest'>
						<ModifyGuestSection/>
					</div>
					<div className='CenterQueue'>
						<QueueTab queues={[[['Accepted'], 'In Studio'], [['Active', 'OffAir', 'OnAir', 'Assigned'], 'Active'], [['Finished'], 'Completed']]}/>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(Main);