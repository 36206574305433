import React from 'react';
import GuestCardRatingPresentation from './GuestCardRatingPresentation';

function GuestCardPresentation(props) {
	function curryOnSetSelectedGuest() {
		return props.onSetSelectedGuest(props.guest);
	}
	function getGroupColor(group) {
		switch (group) {
		case 1: {
			return '#ff0000';
		}
		case 2: {
			return '#00ff00';
		}
		case 3: {
			return '#0000ff';
		}
		case 4: {
			return '#ffff00';
		}
		default: {
			return '#888888';
		}
		}
	}
	function getDeltaTime() {
		let resultingTime = props.currentTime - props.guest.JavascriptDate.getTime();
		let Hours = Math.floor(resultingTime/(1000 * 60 * 60)); 
		resultingTime -= (Hours * 1000 * 60 * 60);
		let Minutes = Math.floor(resultingTime /(1000 * 60));
		resultingTime -= (Minutes * 1000 * 60);
		let Seconds = Math.floor(resultingTime/1000);
		if (Hours < 10) {
			Hours = '0' + Hours;
		}
		if (Minutes < 10) {
			Minutes = '0' + Minutes;
		}
		if (Seconds < 10) {
			Seconds = '0' + Seconds;
		}
		if (!Hours || !Minutes || !Seconds) {
			return '';
		}
		if (Hours >= 24) {
			return '1 Day +';
		}
		return Hours + ':' + Minutes + ':' + Seconds;
	}

	return (
		<div
			className={'GuestCardOuter' + (props.selected ? ' GuestCardActive' : '')}
			onClick={curryOnSetSelectedGuest}
		>
			<div className='GuestCardInner'
				style={{
					borderLeft: 'calc(6px + 0.25vmin) solid '+getGroupColor(props.guest.ColorCode)
				}}
			>
				<div className='GuestCardHeader' >
					<div className='StatusIndicatorWrapper'>
						<div className={'StatusIndicator' + (props.guest.Active ? ' StatusActive' : ' StatusInactive')}/>
					</div>
					<div className='CardWhiteFont'><div>{props.guest.Name}</div></div>
					<div className='CardWhiteFont'><div>{
						getDeltaTime()
					}</div></div>
				</div>
				<div className='GuestCardBody'>
					<div className='GuestCardDetails'>
						<div> {props.guest.GeoLocation} </div>
						<GuestCardRatingPresentation rating={props.guest.Rating}/>
					</div>
					<div className='GuestCardComment'>
						<div>
							{props.guest.Notes}
						</div>
					</div>
					{(props.guest.CallState === 'Assigned' ? <div className='LiveIndicator Assigned'> Assigned </div> : null)}
					{(props.guest.CallState === 'OffAir' ? <div className='LiveIndicator OffAir'>Off-Air</div> : null)}
					{(props.guest.CallState === 'OnAir' ? <div className='LiveIndicator OnAir'>On-Air</div> : null)}
				</div>
			</div>
		</div>
	);
}

export default GuestCardPresentation;