import React from 'react';
import { connect } from 'react-redux';
import { endCurrentShow } from '../../../Redux/Actions/showActions';
import { attemptOpenModal } from '../../../Redux/Actions/userinterfaceActions';
import Modal from '../../Modal';


function mapStateToProps(store) {
	return ({
		show: {
			currentShow: store.show.currentShow
		}
	});
}

class EndShowButtone extends React.Component {
	constructor(props) {
		super(props);

		this.onEndCurrentShow = this.onEndCurrentShow.bind(this);
		this.attemptToOpenEndShowModal = this.attemptToOpenEndShowModal.bind(this);

		this.state = {
			endShowModalID: 'EndShowModal'
		};

	}
	
	onEndCurrentShow() {
		if (!this.props.show.currentShow) {
			return;
		}
		this.props.dispatch(endCurrentShow(this.props.show.currentShow));
	}

	attemptToOpenEndShowModal() {
		if (!this.props.show.currentShow) {
			return;
		}
		this.props.dispatch(attemptOpenModal(this.state.endShowModalID));
	}

	render() {
		return (
			<div>
				<button className={(this.props.show.currentShow ? 'ActiveEndShowButton' : 'InactiveEndShowButton')} onClick={this.attemptToOpenEndShowModal}> End Show </button>
				<Modal
					className='EndShowModal ModalStyle'
					onSuccess={this.onEndCurrentShow}
					modalID={this.state.endShowModalID}
					modalContent={<div><div>Are you Sure you want to End the Current Show?</div></div>}
					OkMessage={'End Show'}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps)(EndShowButtone);