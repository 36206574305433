
function userinterface(state={
	openModalID: undefined,
	currentTime: undefined,
	autoForward: false,
	showCreationError: ''
}, action) {
	switch (action.type) {
	case 'ATTEMPT_MODAL_OPEN': {
		if (state.openModalID) {
			return state;
		}
		return {...state, openModalID: action.payload, showCreationError: ''};
	}
	case 'ATTEMPT_MODAL_CLOSE': {
		if (action.payload !== state.openModalID) {
			return state;
		}
		return {...state, openModalID: undefined, showCreationError: ''};
	}
	case 'UPDATE_CURRENT_TIME': {
		if (action.payload) {
			return {...state, currentTime: action.payload};
		}
		return state;
	}
	case 'UPDATE_AUTO_FORWARD': {
		if (action.payload !== undefined || action.payload !== null) {
			return {...state, autoForward: action.payload};
		}
		return state;
	}
	case 'ON_CREATE_SHOW_REJECTED': {
		if (!action.payload || !action.payload.Error)
		{
			return state;
		}
		return {...state, showCreationError: action.payload.Error || ''};
	}
	default:
		return state;
	}
}

export default userinterface;