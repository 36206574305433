import React from 'react';
import './TabPresentation.css';

function TabsPresentation(props) {
	let tabList = [];
	let activeTab = [['UNDEFINED'], 'UNDEFINED'];
	function curryActiveTab(activeTab) {
		return () => {props.onSetActiveTab(activeTab);};
	}
	if (props.tabList) {
		tabList = props.tabList;
	}
	if (props.activeTab) {
		activeTab = props.activeTab;
	}
	return (
		<div className='TabContainer'>
			{tabList.map( tabName => {
				return (
					<div
						key={tabName[1]}
						className={(tabName[0][0] === activeTab[0][0] ? 'TabActive' : 'TabInactive') + ' Tab'}
						tabvalue={tabName[0]}
						onClick={curryActiveTab(tabName)}
					>
						<div className='TabName'>
							{tabName[1]}
						</div>
						{tabName[2] !== undefined ? (<div className='QueueCount'>
							{tabName[2]}
						</div>) : null}
					</div>);
			})}
		</div>
	);
}

export default TabsPresentation;