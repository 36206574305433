import React from 'react';

function ShowSelectorPresentation(props) {
	return (
		<select onChange={props.onSetCurrentShow} value={props.currentShowId}>
			<option value={undefined} disabled>-- Select a Show --</option>
			{props.showList.map((show) => {
				return (
					<option key={show.ShowId} value={show.ShowId}>{show.ShowName}</option>
				);
			})}
		</select>
	);
}

export default ShowSelectorPresentation;