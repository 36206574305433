import React from 'react';

function GuestDemographicTextFieldPresentation(props) {
	return (
		<div className='GuestFieldWrapperInner'>
			<div className='GuestFieldLabelWrapper'>
				<label className='GuestFieldLabel centered-text' htmlFor={'text-input-' + props.id}> {props.label} </label>
			</div>
			<input className='GuestFieldInput' type='text' id={'text-input-' + props.id} placeholder={props.placeholder} onChange={props.onChange} field={props.id} value={props.value}/>
		</div>
	);
}

export default GuestDemographicTextFieldPresentation;