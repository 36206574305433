import React from 'react';

function GuestDemographicPriorityPresentation(props) {
	return (
		<div className='PriorityWrapperOuter'>
			<div value={4} className={'PriorityWrapperInner centered-text' + (props.currentPriority === 4 ? ' ActivePriority' : ' InactivePriority')} onClick={props.onUpdatePriority}>
				<div value={4}> Highest Priority</div>
			</div>
			<div value={3} className={'PriorityWrapperInner centered-text' + (props.currentPriority === 3 ? ' ActivePriority' : ' InactivePriority')} onClick={props.onUpdatePriority}>
				<div value={3}> High Priority </div>
			</div>
			<div value={2} className={'PriorityWrapperInner centered-text' + (props.currentPriority === 2 ? ' ActivePriority' : ' InactivePriority')} onClick={props.onUpdatePriority}>
				<div value={2}> Normal Priority </div>
			</div>
			<div value={1} className={'PriorityWrapperInner centered-text' + (props.currentPriority === 1 ? ' ActivePriority' : ' InactivePriority')} onClick={props.onUpdatePriority}>
				<div value={1}> Low Priority </div>
			</div>
			<div value={0} className={'PriorityWrapperInner centered-text' + (props.currentPriority === 0 ? ' ActivePriority' : ' InactivePriority')} onClick={props.onUpdatePriority}>
				<div value={0}> Lowest Priority </div> 
			</div>
		</div>
		/*
		<select value={props.currentPriority} onChange={props.onUpdatePriority}>
			<option value={0}> Lowest </option>
			<option value={1}> Low </option>
			<option value={2}> Normal </option>
			<option value={3}> High </option>
			<option value={4}> Highest </option>
		</select>*/
	);
}

export default GuestDemographicPriorityPresentation;