import React from 'react';
import { connect } from 'react-redux';

import CustomVideo from './CustomVideo';
import LocalMediaHandler from '../../../../Utilities/Gnural/LocalMediaHandler/index';
import RemoteMediaHolder from '../../../../Utilities/Gnural/RemoteMediaHolder';
import '../../../../Utilities/Gnural/WebRTCStats';
import GuestBrowserInfo from './GuestBrowserInfo';
import './GuestMediaSection.css';
import MediaSettings from '../../../../Assets/Media Settings.svg';
import CallStatsPresentation from './CallStatsPresentation';

function mapStateToProps(store) {
	return ({
		localMediaStreamID: store.media.localMediaStreamID,
		remoteMediaStreamID: store.media.remoteMediaStreamID,
		currentAudioOutput: store.media.currentAudioOutput,
		autoForward: store.userinterface.autoForward,
		showCreationError: store.userinterface.showCreationError
	});
}

class GuestMediaSection extends React.Component {
	constructor(props) {
		super(props);

		this.state={
			localStream: undefined,
			remoteStream: undefined,
			showBrowserInfo: false,
			enableFraming: false
		};

		this.ToggleFraming = this.ToggleFraming.bind(this);
	}

	componentDidMount() {
		if (this.props.localMediaStreamID) {
			this.setState({
				localStream: LocalMediaHandler.getLocalMediaStream()
			});
		}
		if (this.props.remoteMediaStreamID) {
			this.setState({
				remoteStream: RemoteMediaHolder.getRemoteStream()
			});
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps === this.props) {
			return;
		}
		this.setState({
			localStream: LocalMediaHandler.getLocalMediaStream(),
			remoteStream: RemoteMediaHolder.getRemoteStream()
		});
	}

	ToggleFraming() {
		this.setState({
			enableFraming: !this.state.enableFraming
		});
	}

	render() {
		return (
			<div className='CustomVideoWrapperOuter'>
				<div className='AutoForwardWarning centered-text'>{(this.props.showCreationError ? this.props.showCreationError : '')}</div>
				<div className='AutoForwardWarning centered-text'>{(this.props.autoForward ? 'AUTO-FORWARDING IS ACTIVE' : '')}</div>
				<div className='CustomVideoWrapper'>
					<div className={'ToggleFramingButton' + (this.state.enableFraming ? ' ActiveFraming' : '')} onClick={this.ToggleFraming}><div>[&nbsp;&nbsp;&nbsp;&nbsp;]</div></div>
					<CustomVideo
						className='LocalVideo'
						srcObject={this.state.localStream}
						muted={true}
					/>
					<CustomVideo
						className='RemoteVideo'
						currentAudioOutput={this.props.currentAudioOutput}
						srcObject={this.state.remoteStream}
						shouldContainFraming={this.state.enableFraming}
						muted={false}
					/>
					<CallStatsPresentation/> 
					<div className='ShowBrowserInfoWrapper'>
						<div className='BrowserInfoHeader centered-text'><img src={MediaSettings} className='BrowserInfoHeaderImage' alt='Remote Media Settings Button'/></div>
						<GuestBrowserInfo/>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(GuestMediaSection);



