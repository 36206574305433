import React from 'react';

function GuestDemographicsRatingPresentation(props) {
	function getProperClassName(value) {
		if (props.currentRating >= value) {
			return 'RatingActive ' + (props.useAltAnimation ? 'AltAnimation' : 'MainAnimation');
		}
		return 'RatingInactive';
	}
	return (
		<div className='RatingContainer'>
			<div className={getProperClassName(1)} value={1} onClick={props.onUpdateRating}><div value={1} className='RatingElement'/></div>
			<div className={getProperClassName(2)} value={2} onClick={props.onUpdateRating}><div value={2} className='RatingElement'/></div>
			<div className={getProperClassName(3)} value={3} onClick={props.onUpdateRating}><div value={3} className='RatingElement'/></div>
			<div className={getProperClassName(4)} value={4} onClick={props.onUpdateRating}><div value={4} className='RatingElement'/></div>
			<div className={getProperClassName(5)} value={5} onClick={props.onUpdateRating}><div value={5} className='RatingElement'/></div>
		</div>
	);
}

export default GuestDemographicsRatingPresentation;