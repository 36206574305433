import Logger from '../../Logging';
import ReduxConnector from '../ReduxConnector';

import { setMediaInformation, setNewLocalMediaStream, updateVideoResolutionObject, setNewMediaDevice } from '../../../Redux/Actions/mediaActions';


const INFO = 'Utilities/Gnural/LocalMediaHandler';

class LocalMediaHandler {
	constructor() {
		this.init = this.init.bind(this);
		this.promptLocalMedia = this.promptLocalMedia.bind(this);
		this.getLocalMediaInformation = this.getLocalMediaInformation.bind(this);
		this.updateProperties = this.updateProperties.bind(this);
		this.StartLocalMedia = this.StartLocalMedia.bind(this);
		this.getLocalMediaStream = this.getLocalMediaStream.bind(this);
		this.getResolutions = this.getResolutions.bind(this);

		this.init();
		this.props = { currentAudioInput: {}, currentAudioOutput: {}, currentVideoInput: {}, currentVideoResolution: {}, videoREsolutionObject: {}};
		this.localStream = undefined;
		ReduxConnector.store.InjectLocalMediaHandler(this);
	}

	async updateProperties(newProperties) {
		const oldProperties = this.props;
		this.props = newProperties || { currentAudioInput: {}, currentAudioOutput: {}, currentVideoInput: {}};

		let newAudioInputDevice = this.props.currentAudioInput.deviceId || (oldProperties ? oldProperties.currentAudioInput.deviceId : '');
		let newAudioOutputDevice = this.props.currentAudioOutput.deviceId || (oldProperties ? oldProperties.currentAudioOutput.deviceId : '');
		let newVideoInputDevice = this.props.currentVideoInput.deviceId || (oldProperties ? oldProperties.currentVideoInput.deviceId : '');
		let newVideoResolution = this.props.currentVideoResolution.friendlyName || (oldProperties ? oldProperties.currentVideoResolution.friendlyName : '');
		
		let shouldRestartMedia = false;
		if (!oldProperties || oldProperties.currentAudioInput.deviceId !== newAudioInputDevice) {
			localStorage.setItem('CallInManagerDefaultAudioInputDevice', newAudioInputDevice);
			shouldRestartMedia = true;
		}
		if (!oldProperties || oldProperties.currentAudioOutput.deviceId !== newAudioOutputDevice) {
			localStorage.setItem('CallInManagerDefaultAudioOutputDevice', newAudioOutputDevice);
			// Do something else;
		}

		if (newVideoInputDevice && (!oldProperties || oldProperties.currentVideoResolution.friendlyName !== newVideoResolution)) {
			localStorage.setItem('CallInManagerDefaultVideoResolution-' + newVideoInputDevice, newVideoResolution);
			shouldRestartMedia = true;
		}

		if (!oldProperties || oldProperties.currentVideoInput.deviceId !== newVideoInputDevice) {
			localStorage.setItem('CallInManagerDefaultVideoInputDevice', newVideoInputDevice);
			shouldRestartMedia = true;
			let videoResolutionList = this.props.videoResolutionObject[newVideoInputDevice];
			if (!videoResolutionList) {
				videoResolutionList = await this.getResolutions(newVideoInputDevice);
				ReduxConnector.dispatch(updateVideoResolutionObject(videoResolutionList, newVideoInputDevice));
				shouldRestartMedia = false;
			}
			const defaultResolutionFriendly = localStorage.getItem('CallInManagerDefaultVideoResolution-' + newVideoInputDevice);
			let defaultResolution = videoResolutionList.filter((resolution) => {
				return resolution.friendlyName === defaultResolutionFriendly;
			})[0];
			if (!defaultResolution) {
				defaultResolution = videoResolutionList[0] || {};
			}
			ReduxConnector.dispatch(setNewMediaDevice('videoResolutionObject', defaultResolution));
		}

		if (shouldRestartMedia) {
			this.StartLocalMedia();
		}

	}

	async StartLocalMedia() {
		if (!this.props.currentAudioInput.deviceId || !this.props.currentVideoInput.deviceId) {
			Logger.warn(INFO, 'Invalid Audio Input or Video Input Device');
		}

		const rtcVideoConstraints = (
			this.props.currentVideoInput.deviceId ? 
				{deviceId: {exact: this.props.currentVideoInput.deviceId}} : 
				false
		);

		const rtcAudioConstraints = (
			this.props.currentAudioInput.deviceId ? 
				{deviceId: {exact: this.props.currentAudioInput.deviceId}, channelCount: 2} :
				false
		);

		if (this.props.currentVideoResolution && this.props.currentVideoResolution.width && this.props.currentVideoResolution.height) {
			rtcVideoConstraints.width = {exact: this.props.currentVideoResolution.width};
			rtcVideoConstraints.height = {exact: this.props.currentVideoResolution.height};
		}

		const rtcConstraints = {
			video: rtcVideoConstraints, 
			audio: rtcAudioConstraints
		};

		try {
			if (this.localStream) {
				this.localStream.getVideoTracks().forEach(track => {
					if (track) {
						track.stop();
					}
				});
				this.localStream.getAudioTracks().forEach(track => {
					if (track) {
						track.stop();
					}
				});
			}
			this.localStream = await navigator.mediaDevices.getUserMedia(rtcConstraints);
			ReduxConnector.dispatch(setNewLocalMediaStream(this.localStream));
		} catch(e) {
			Logger.error(INFO, 'Failed to get Local Media: ', e);
		}
	}

	getLocalMediaStream() {
		return this.localStream;
	}

	async promptLocalMedia() {

		let mediaConstraints = {
			video: true,
			audio: true
		};
		let stream;
		try {
			stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
			stream.getVideoTracks().forEach(track => {
				if (track) {
					track.stop();
				}
			});
			stream.getAudioTracks().forEach(track => {
				if (track) {
					track.stop();
				}
			});
		} catch (e) {
			Logger.error(INFO, 'Failed to Prompt Local Media: ', e);
			Logger.warn(INFO, 'Attempting to Prompt Just Video Media');
			try {
				mediaConstraints = {
					video: true,
					audio: false
				};
				stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
				stream.getVideoTracks().forEach(track => {
					if (track) {
						track.stop();
					}
				});
			} catch (e) {
				Logger.error(INFO, 'Failed to Prompt Just Video Media: ', e);
			}
			Logger.warn(INFO, 'Attempting to Promp Just Audio Media');
			try {
				mediaConstraints = {
					video: false,
					audio: true
				};
				stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
				stream.getAudioTracks().forEach(track => {
					if (track) {
						track.stop();
					}
				});
			} catch (e) {
				Logger.error(INFO, 'Failed to Promp Just Audio Media: ', e);
			}
		}
	}

	async tryMediaAsync(constraints, resolution) {
		try {
			const videoStream = await navigator.mediaDevices.getUserMedia(constraints);
			if (videoStream) {
				videoStream.getVideoTracks().forEach((track) => {
					track.stop();
				});
			}
			return resolution;
		} catch (e) {
			return null;
		}
	}

	async getResolutions(deviceId) {
		if (!deviceId) {
			return [];
		}

		const possibleResolutionList = [
			{width: 1920, height:1080, friendlyName: '1920x1080'},
			{width: 1280, height: 720, friendlyName: '1280x720'},
			{width: 960, height: 720, friendlyName: '960x720'},
			{width: 960, height: 540, friendlyName: '960x540'},
			{width: 640, height: 480, friendlyName: '640x480'},
			{width: 640, height: 360, friendlyName: '640x360'},
			{width: 320, height: 180, friendlyName: '320x180'}
		];

		const videoResolutionRequestList = [];
		const finalVideoResolutionList = [];	

		let constraint;
		
		possibleResolutionList.forEach(resolution => {
			constraint = {
				video: {
					deviceId: {exact: deviceId},
					width: {exact: resolution.width},
					height: {exact: resolution.height}
				},
				audio: false
			};
			videoResolutionRequestList.push(this.tryMediaAsync(constraint, resolution));
		});

		let response;
		for (let index = 0; index < videoResolutionRequestList.length; index++) {
			response = await videoResolutionRequestList[index];
			if (response) {
				finalVideoResolutionList.push(response);
			}
		}
		return finalVideoResolutionList;	
	}

	async getLocalMediaInformation() {
		try {
			const mediaDevices = await navigator.mediaDevices.enumerateDevices();
			const audioInputList = [];
			const audioOutputList = [];
			const videoInputList = [];
			
			mediaDevices.forEach(device => {
				if (!device || !device.kind) {
					return;
				}
				switch(device.kind) {
				case 'audioinput': {
					return audioInputList.push(device);
				}
				case 'audiooutput': {
					return audioOutputList.push(device);
				}
				case 'videoinput': {
					return videoInputList.push(device);
				}
				default:
					return;
				}
			});

			const defaultAudioInputDeviceID = localStorage.getItem('CallInManagerDefaultAudioInputDevice');
			const defaultAudioOutputDeviceID = localStorage.getItem('CallInManagerDefaultAudioOutputDevice');
			const defaultVideoInputDeviceID = localStorage.getItem('CallInManagerDefaultVideoInputDevice');

			const defaultAudioInputDevice = audioInputList.filter(device => device.deviceId === defaultAudioInputDeviceID)[0] || audioInputList[0] || {};
			const defaultAudioOutputDevice = audioOutputList.filter(device => device.deviceId === defaultAudioOutputDeviceID)[0] || audioOutputList[0] || {};
			const defaultVideoInputDevice = videoInputList.filter(device => device.deviceId === defaultVideoInputDeviceID)[0] || videoInputList[0] || {};
		
			const videoResolutionObject = {};
			let defaultVideoResolution = {};
			if (defaultVideoInputDevice) {
				const videoResolutions = await this.getResolutions(defaultVideoInputDevice.deviceId);
				defaultVideoResolution = localStorage.getItem('CallInManagerDefaultVideoResolution-' + defaultVideoInputDevice.deviceId);
				if (!defaultVideoResolution && videoResolutions.length >= 1) {
					defaultVideoResolution = videoResolutions[0];
				}
				videoResolutionObject[defaultVideoInputDevice.deviceId] = videoResolutions;
			}
			if (!defaultVideoResolution) {
				defaultVideoResolution = {};
			}

			ReduxConnector.dispatch(setMediaInformation({
				audioInputList,
				audioOutputList,
				videoInputList,
				videoResolutionObject,
				currentAudioInput: defaultAudioInputDevice,
				currentAudioOutput: defaultAudioOutputDevice,
				currentVideoInput: defaultVideoInputDevice,
				currentVideoResolution: defaultVideoResolution
			}));

		} catch (e) {
			Logger.error(INFO, 'Failed to get Local Media Information: ', e);
		}
	}

	async refreshLocalMedia() {
		await this.getLocalMediaInformation();
		await this.StartLocalMedia();
	}

	async init() {
		await this.promptLocalMedia();
		await this.getLocalMediaInformation();
	}
}

function neededStoreVariables(store) {
	return store.media;
}

const Handler = new LocalMediaHandler();

ReduxConnector.connect(neededStoreVariables, Handler.updateProperties);

export default Handler;