import React from 'react';
import { connect } from 'react-redux';
import { attemptOpenModal } from '../../../../Redux/Actions/userinterfaceActions';
import { createNewShow } from '../../../../Redux/Actions/showActions';

import NewShowButtonPresentation from './NewShowButtonPresentation';
import Modal from '../../../Modal';

function mapStateToProps(store) {
	return ({
		show: {
			shows: store.show.shows
		},
		user: store.user,
	});
}

class NewShowButton extends React.Component {
	constructor(props) {
		super(props);

		this.onNewShowUpdate = this.onNewShowUpdate.bind(this);
		this.onNewLTAUser = this.onNewLTAUser.bind(this);
		this.openNewShowModal = this.openNewShowModal.bind(this);
		this.onCreateShow = this.onCreateShow.bind(this);


		this.state={
			showList: [],
			currentNewShowName: '',
			currentLTAUser: undefined
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps === this.props) {
			return;
		}

		const ActiveShowSet = new Set(this.props.show.shows.Active.map(show => show.ShowName));
		const InactiveShowList = [...new Set(this.props.show.shows.Inactive.map(show => show.ShowName))];
		
		let showList = InactiveShowList.filter(show => !ActiveShowSet.has(show));
		let currentLTAUser = (!this.state.currentLTAUser && nextProps.user.LTAUsers && nextProps.user.LTAUsers.length >= 1 ? nextProps.user.LTAUsers[0] : undefined);
		if (!currentLTAUser) {
			this.setState({
				showList
			});
			return;
		}
		this.setState({
			showList,
			currentLTAUser
		});
	}

	onNewShowUpdate(event) {
		if (!event || !event.target) {
			return;
		}
		this.setState({
			currentNewShowName: event.target.value
		});
	}

	openNewShowModal() {
		this.props.dispatch(attemptOpenModal('NewShowModal'));	
	}

	onNewLTAUser(event) {
		if (!event || !event.target) {
			return;
		}
		const parsedNewID = Number.parseInt(event.target.value, 10);
		const currentLTAUser = this.props.user.LTAUsers.filter(user => user.UserId === parsedNewID);
		if (!currentLTAUser.length === 0) {
			return;
		}
		this.setState({
			currentLTAUser: currentLTAUser[0]
		});
	}

	onCreateShow() {
		if (this.state.currentNewShowName.length === 0 ||
			!this.props.user.UserId ||
			!this.state.currentLTAUser ||
			!this.state.currentLTAUser.UserId
		) {
			return;
		}
		this.props.dispatch(
			createNewShow(
				this.state.currentNewShowName,
				this.props.user,
				this.state.currentLTAUser
			)
		);
	}

	render() {
		return(
			<div>
				<button className='custom-secondary-button' onClick={this.openNewShowModal}> New Show </button>
				<Modal
					modalContent={
						<NewShowButtonPresentation
							currentNewShowName={this.state.currentNewShowName}
							currentLTAUser={this.state.currentLTAUser}
							showList={this.state.showList}
							LTAUsers={this.props.user.LTAUsers}
							onNewShowUpdate={this.onNewShowUpdate}
							onNewLTAUser={this.onNewLTAUser}
						/>
					}
					className={'ModalStyle'}
					modalID={'NewShowModal'}
					onSuccess={this.onCreateShow}
					OkMessage={'Create Show'}
				/>
			</div>
		);
	}

}

export default connect(mapStateToProps)(NewShowButton);