import React from 'react';

function GuestDemographicTextAreaFieldPresentation(props) {
	return (
		<div className='GuestTextAreaWrapperInner'>
			<div className='GuestTextAreaLabelWrapper'>
				<label className='centered-text' htmlFor={'textarea-' + props.id}> {props.label} </label>
			</div>
			<textarea placeholder={'Enter Notes for the Guest...'} className='GuestTextArea' id={'textarea-' + props.id} onChange={props.onChange} field={props.id} value={props.value}/>
		</div>
	);
}

export default GuestDemographicTextAreaFieldPresentation;
