import React from 'react';

function SettingsMenuPresentation(props) {
	function precomputedOnUpdateSelectGeneric(selectType) {
		return (event) => {props.onUpdateSelectGeneric(selectType, event);};
	}
	return (
		<div className='SettingsMenuPresentationWrapper'>
			<div className='centered-text'> Local Media Settings: </div>
			<select value={(props.currentVideoInput ? props.currentVideoInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('videoInputList')}>
				<option value={undefined} disabled>- Select Video Input Device -</option>
				{props.videoInputList.map(input => {
					return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
				})}
			</select>
			<select value={props.currentVideoResolution ? props.currentVideoResolution.friendlyName : ''} onChange={props.onUpdateVideoResolution}>
				<option value={undefined} disabled>- Select Video Resolution -</option>
				{props.resolutionList.map(resolution => {
					return <option value={resolution.friendlyName} key={'Input-Selector-' + resolution.friendlyName}>{resolution.friendlyName}</option>;
				})}
			</select>
			<select value={(props.currentAudioInput ? props.currentAudioInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioInputList')}>]
				<option value={undefined} disabled>- Select Audio Input Device -</option>
				{props.audioInputList.map(input => {
					return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
				})}
			</select>
			<select value={(props.currentAudioOutput ? props.currentAudioOutput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioOutputList')}>
				<option value={undefined} disabled>- Select Audio Output Device -</option>
				{props.audioOutputList.map(output => {
					return <option value={output.deviceId} key={output.deviceId}>{output.label}</option>;
				})}
			</select>
			<div><input type='checkbox' name='Autoforward' value='Autoforward' onChange={props.onAutoforwardChange} checked={props.currentAutoForward}/> Auto-Forward Guests to Studio</div>
			<button onClick={props.onRefreshLocalMedia}> Refresh </button>
		</div>
	);
}

export default SettingsMenuPresentation;