import React from 'react';

function ShowLinkButtonPresentation(props) {
    props = {
        ...props, 
        inputRef: React.createRef()
    }
    
    function generateShowUrl()
    {
        if (!props.currentShow || !props.currentShow.ShowName)
        {
            return 'There is no Active Show';
        }
        if (!props.selectedGuestSite)
        {
            return 'There is no valid Guest Site Selected';
        }
        let tempShowName = encodeURIComponent(props.currentShow.ShowName).replaceAll('.', '%2E').replaceAll('!', '%21').replaceAll('-', '%2D').replaceAll('_', '%5F').replaceAll('~', '%7E').replaceAll('*', '%2A').replaceAll('`', '%60').replaceAll('(', '%28').replaceAll(')', '%29');

        if (!props.embedUrl)
        {
            return 'https://' + props.selectedGuestSite + '/' + tempShowName;
        }
        return 'https://' + props.selectedGuestSite + '/' + tempShowName + '?embedurl=' +
                encodeURIComponent(props.embedUrl);
    }

    function copyToClipboard()
    {
        if (!props || !props.inputRef || !props.inputRef.current)
        {
            return;
        }
        props.inputRef.current.select();
        document.execCommand('copy');
    }

	return (
		<div className='ShowLinkPresentationWrapper'>
			<div className='centered-text'> Show Link Settings: </div>
            <div>
                <select value={(props.selectedGuestSite ? props.selectedGuestSite : "")} 
                        onChange={props.onUpdateSelectedGuestSite}>
                            <option value={undefined} disabled>- Select Guest Site -</option>
                            {props.guestSiteList.map(site => {
                                return <option value={site} key={'guest-site-select-' + site}>{site}</option>;
                            })}
                        </select>
            </div>
            <div className={'ShowUrlWrapper'}>
                <input ref={props.inputRef} type={'Text'} value={generateShowUrl()} className={'ShowUrl'} readOnly={true}/>
                <div onClick={copyToClipboard}>⎘</div>
            </div>
		</div>
	);
}
//<input type={'text'} placeholder={'Enter Embed Stream Url...'} value={props.embedUrl} 
//       onChange={props.onUpdateEmbedUrl}/>

export default ShowLinkButtonPresentation;