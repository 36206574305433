import React from 'react';
import { connect } from 'react-redux';

import GuestBrowserInfoPresentation from './GuestBrowserInfoPresentation';

import { setGuestDevice, reconnectPeer } from '../../../../../Redux/Actions/guestActions';


function mapStateToProps(store) {
	return ({
		PeerGuid: store.show.selectedGuest.guest.CookieGuid,
		PeerId: store.media.remotePeerID,
		Context: store.user.Context,
		UserId: store.user.ClientGuid,
		BrowserName: store.show.selectedGuest.browserInfo.BrowserName,
		BrowserVersion: store.show.selectedGuest.browserInfo.BrowserVersion,
		OSVersion: store.show.selectedGuest.browserInfo.OsVersion,
		videoInputList: store.show.selectedGuest.browserInfo.CameraList,
		videoResolutionList: store.show.selectedGuest.browserInfo.CameraResList,
		audioInputList: store.show.selectedGuest.browserInfo.MicList,
		audioOutputList: store.show.selectedGuest.browserInfo.SpkrList,
		currentVideoInput: store.show.selectedGuest.browserInfo.CurrentCamera,
		currentVideoResolution: store.show.selectedGuest.browserInfo.CurrentRes,
		currentAudioInput: store.show.selectedGuest.browserInfo.CurrentMic,
		currentAudioOutput: store.show.selectedGuest.browserInfo.CurrentSpkr
	});
}

class GuestBrowserInfo extends React.Component {
	constructor(props) {
		super(props);

		this.onUpdateSelectGeneric = this.onUpdateSelectGeneric.bind(this);
		this.onReconnectPeer = this.onReconnectPeer.bind(this);
	}

	onReconnectPeer() {
		this.props.dispatch(reconnectPeer(this.props.Context, this.props.PeerId, this.props.UserId));
	}

	onUpdateSelectGeneric(selectType, event) {
		if (!event || !event.target || !selectType) {
			return;
		}
		const currentlySelectedObject = event.target.options[event.target.selectedIndex].value;
		if (!currentlySelectedObject) {
			return;
		}
		let DeviceType;
		switch(selectType) {
		case 'videoInputList': {
			DeviceType = 'Camera';
			break;
		}
		case 'videoResolutionList': {
			DeviceType = 'CamRes';
			break;
		}
		case 'audioInputList': {
			DeviceType = 'Mic';
			break;
		}
		case 'audioOutputList': {
			DeviceType = 'Spkr';
			break;
		}
		default: 
			return;
		}
		if (!this.props.PeerGuid || !this.props.UserId) {
			return;
		}
		this.props.dispatch(setGuestDevice(this.props.PeerGuid, this.props.UserId, DeviceType, currentlySelectedObject));
	}
	render() {
		return (
			<GuestBrowserInfoPresentation
				{...this.props}
				onUpdateSelectGeneric={this.onUpdateSelectGeneric}
				onReconnectPeer={this.onReconnectPeer}
			/>
		);
	}
}

export default connect(mapStateToProps)(GuestBrowserInfo);