import React from 'react';
import GuestDemographicTextFieldsPresentation from './GuestDemographicsTextFields/GuestDemographicTextFieldsPresentation';
import GuestDemographicsTextAreaFieldPresentation from './GuestDemographicsTextFields/GuestDemographicTextAreaFieldPresentation';
import GuestDemographicsRatingPresentation from './GuestDemographicsRatingPresentation';
import GuestDemographicGroupPresentation from './GuestDemographicGroupPresentation';
import GuestDemographicsPriorityPresentation from './GuestDemographicPriorityPresentation';

import './GuestDemographicsSection.css';
import './GuestDemographicsTextFields/GuestDemographicsTextFields.css';

import { updateGuest } from '../../../../Redux/Actions/guestActions';
import { connect } from 'react-redux';

function mapStateToProps(store) {
	return ({
		selectedGuest: store.show.selectedGuest.guest,
		Context: store.user.Context || '',
		PeerId: store.user.PeerId || '',
		UserId: store.user.UserId || ''
	});
}

class GuestDemographicSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			Name: '',
			Email: '',
			Phone: '',
			GeoLocation: '',
			Twitter: '',
			Facebook: '',
			Rating: undefined,
			ColorCode: undefined,
			Priority: undefined,
			Notes: '',
			CookieGuid: undefined,
			toggleAltAnimation: false,
			isChanged: false
		};

		this.onChangeGeneric = this.onChangeGeneric.bind(this);
		this.onUpdateRating = this.onUpdateRating.bind(this);
		this.onUpdateGroup = this.onUpdateGroup.bind(this);
		this.onUpdatePriority = this.onUpdatePriority.bind(this);
		this.updateGuest = this.updateGuest.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(nextProps, forceUpdate=false) {
		if (nextProps !== this.props || forceUpdate) {
			if (!nextProps.selectedGuest) {
				this.setState({
					Name: '',
					Email: '',
					Phone: '',
					GeoLocation: '',
					Twitter: '',
					Facebook: '',
					Rating: undefined,
					ColorCode: undefined,
					Group: '',
					Priority: undefined,
					Notes: '',
					CookieGuid: undefined,
					isChanged: false
				});
				return;
			}
			
			this.setState({
				Name: (nextProps.selectedGuest.Name ? nextProps.selectedGuest.Name : ''),
				Email: (nextProps.selectedGuest.Email ? nextProps.selectedGuest.Email : ''),
				Phone: (nextProps.selectedGuest.Phone ? nextProps.selectedGuest.Phone : ''),
				GeoLocation: (nextProps.selectedGuest.GeoLocation ? nextProps.selectedGuest.GeoLocation : ''),
				Twitter: (nextProps.selectedGuest.Twitter ? nextProps.selectedGuest.Twitter : ''),
				Facebook: (nextProps.selectedGuest.Facebook ? nextProps.selectedGuest.Facebook : ''),
				Rating: (nextProps.selectedGuest.Rating !== null ? nextProps.selectedGuest.Rating : undefined),
				ColorCode: (nextProps.selectedGuest.ColorCode !== null ? nextProps.selectedGuest.ColorCode : undefined),
				Priority: (nextProps.selectedGuest.Priority !== null ? nextProps.selectedGuest.Priority : undefined),
				Notes: (nextProps.selectedGuest.Notes ? nextProps.selectedGuest.Notes : ''),
				CookieGuid: nextProps.selectedGuest.CookieGuid,
				toggleAltAnimation: !this.state.toggleAltAnimation,
				isChanged: false
			});
		}
	}
	componentDidMount() {
		this.handleChange(this.props, true);
	}
	componentWillReceiveProps(nextProps) {
		this.handleChange(nextProps);
	}

	onChangeGeneric(event) {
		if (!event.target || !this.state.CookieGuid || !event.target.getAttribute('field')) {
			return;
		}
		this.setState({
			[event.target.getAttribute('field')]: event.target.value,
			isChanged: true
		});
	}

	onUpdateRating(event) {
		if (!event.target || !this.state.CookieGuid) {
			return;
		}
		let newRating = this.state.Rating;
		let eventRating = Number.parseInt(event.target.getAttribute('value'), 10);
		if (eventRating === newRating) {
			newRating-=1;
		} else {
			newRating = eventRating;
		}

		this.setState({
			Rating: newRating,
			toggleAltAnimation: !this.state.toggleAltAnimation,
			isChanged: true
		});
	}

	onUpdateGroup(event) {
		if (!event.target || !this.state.CookieGuid) {
			return;
		}
		this.setState({
			ColorCode: Number.parseInt(event.target.getAttribute('value'), 10),
			isChanged: true
		});
	}

	onUpdatePriority(event) {
		if (!event || !event.target || !this.state.CookieGuid || event.target.getAttribute('value') === null) {
			return;
		}
		this.setState({
			Priority: Number.parseInt(event.target.getAttribute('value'), 10),
			isChanged: true
		});
	}

	updateGuest() {
		if (this.state.CookieGuid) {
			this.props.dispatch((updateGuest({...this.props.selectedGuest, ...this.state},
				'CIM-UserId:' + this.props.UserId + '-Context:' + this.props.Context + '-PeerId:' + this.props.PeerId)));
		}
	}

	render() {
		return (
			<div className='DemographicsWrapper'>
				<div className='RatingGroupPriorityWrapper'>
					<GuestDemographicsPriorityPresentation currentPriority={this.state.Priority} onUpdatePriority={this.onUpdatePriority}/>
					<GuestDemographicGroupPresentation numberOfOptions={5} onUpdateGroup={this.onUpdateGroup} currentGroup={this.state.ColorCode}/>
					<GuestDemographicsRatingPresentation onUpdateRating={this.onUpdateRating} currentRating={this.state.Rating} cookieGuid={this.state.cookieGuid} useAltAnimation={this.state.toggleAltAnimation}/>
				</div>
				<GuestDemographicTextFieldsPresentation
					inputList={[
						['Name', 'Name', this.state.Name, 'Enter the Guest\'s Name...'],
						['Email', 'Email', this.state.Email, 'Enter the Guest\'s Email...'],
						['Phone', 'Phone', this.state.Phone, 'Enter the Guest\'s Phone...'],
						['GeoLocation', 'Location', this.state.GeoLocation, 'Enter the Guest\'s Location...'],
						['Twitter', 'Twitter', this.state.Twitter, 'Enter the Guest\'s Twitter Handle...'],
						['Facebook', 'Facebook', this.state.Facebook, 'Enter the Guest\'s Facebook Handle...']
					]}
					onChangeGeneric={this.onChangeGeneric}
				/>
				<div className='GuestTextAreaWrapperOuter'>
					<GuestDemographicsTextAreaFieldPresentation
						id={'Notes'}
						label={'Guest Notes: '}
						value={this.state.Notes}
						onChange={this.onChangeGeneric}
					/>
					<div className='UpdateGuestSaveButtonWrapper'>
						<div className={'UpdateGuestSaveButton centered-text' + (this.state.isChanged ? ' changed' : '')} onClick={this.updateGuest}><div>Save</div></div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(GuestDemographicSection);