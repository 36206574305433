import React from 'react';
import GuestCardPresentation from './GuestCardPresentation';
import './GuestQueue.css';

function GuestQueuePresentation(props) {
	let guestList = [];
	if (props.guestList) {
		guestList = props.guestList;
	}
	return (
		<div className={'GuestQueue'}>
			{guestList.map((guest) => {
				return (
					<GuestCardPresentation key={guest.CookieGuid} currentTime={props.currentTime} guest={guest} selected={guest === props.selectedGuest} onSetSelectedGuest={props.onSetSelectedGuest}/>
				);
			})}
		</div>
	);
}

export default GuestQueuePresentation;