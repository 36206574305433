import React from 'react';
import { connect } from 'react-redux';
import { setNewMediaDevice, refreshLocalMedia } from '../../../../Redux/Actions/mediaActions';
import { attemptOpenModal, updateAutoForward } from '../../../../Redux/Actions/userinterfaceActions';

import SettingsMenuPresentation from './SettingsMenuPresentation';
import Modal from '../../../Modal';
import SettingsImage from '../../../../Assets/Media Settings.svg';

function mapStateToProps(store) {
	return ({
		audioInputList: store.media.audioInputList,
		audioOutputList: store.media.audioOutputList,
		videoInputList: store.media.videoInputList,
		videoResolutionObject: store.media.videoResolutionObject,
		currentAudioInput: store.media.currentAudioInput,
		currentAudioOutput: store.media.currentAudioOutput,
		currentVideoInput: store.media.currentVideoInput,
		currentVideoResolution: store.media.currentVideoResolution,
		autoForward: store.userinterface.autoForward
	});
}

class SettingsButton extends React.Component {
	constructor(props) {
		super(props);
		// Do stuff with binding
		this.onUpdateSelectGeneric = this.onUpdateSelectGeneric.bind(this);
		this.onUpdateVideoResolution = this.onUpdateVideoResolution.bind(this);
		this.openSettingsModal = this.openSettingsModal.bind(this);
		this.onRefreshLocalMedia = this.onRefreshLocalMedia.bind(this);
		this.onAutoforwardChange = this.onAutoforwardChange.bind(this);
	}

	onRefreshLocalMedia() {
		this.props.dispatch(refreshLocalMedia());
	}

	onUpdateSelectGeneric(selectType, event) {
		if (!event || !event.target || !selectType) {
			return;
		}
		const currentlySelectedObject = event.target.options[event.target.selectedIndex].value;
		if (!currentlySelectedObject) {
			return;
		}
		const desiredItem = this.props[selectType].filter(objects => {
			return objects.deviceId === currentlySelectedObject;
		});
		if (!desiredItem[0]) {
			return;
		}
		this.props.dispatch(setNewMediaDevice(selectType, desiredItem[0]));
	}

	onUpdateVideoResolution(event) {
		if (!event || !event.target) {
			return;
		}

		const currentlySelectedResolution = event.target.options[event.target.selectedIndex];
		if (!currentlySelectedResolution) {
			return;
		}
		const desiredResolution = this.props.videoResolutionObject[this.props.currentVideoInput.deviceId].filter(
			(resolution) => {
				return resolution.friendlyName === currentlySelectedResolution.value;
			})[0];
		if (!desiredResolution) {
			return;
		}
		this.props.dispatch(setNewMediaDevice('videoResolutionObject', desiredResolution));
	}

	onAutoforwardChange(event) {
		if (!event || !event.target) {
			return;
		}
		this.props.dispatch(updateAutoForward(!this.props.autoForward));
	}

	openSettingsModal() {
		this.props.dispatch(attemptOpenModal('SettingsModal'));
	}

	render() {
		return (
			<div className='SettingsButtonHeaderWrapper'>
				<div className='SettingsButton' onClick={this.openSettingsModal}>
					<img className='SettingsImage' src={SettingsImage} alt='Local Media Settings Button'/>
				</div>
				<Modal
					modalContent={
						<SettingsMenuPresentation
							currentVideoInput={this.props.currentVideoInput}
							videoInputList={this.props.videoInputList}
							currentAudioInput={this.props.currentAudioInput}
							audioInputList={this.props.audioInputList}
							currentAudioOutput={this.props.currentAudioOutput}
							audioOutputList={this.props.audioOutputList}
							resolutionList={
								(this.props.currentVideoInput && this.props.videoResolutionObject[this.props.currentVideoInput.deviceId] ? 
									this.props.videoResolutionObject[this.props.currentVideoInput.deviceId] : 
									[]
								)
							}
							currentVideoResolution={this.props.currentVideoResolution}
							onUpdateSelectGeneric={this.onUpdateSelectGeneric}
							onUpdateVideoResolution={this.onUpdateVideoResolution}
							onRefreshLocalMedia={this.onRefreshLocalMedia}
							onAutoforwardChange={this.onAutoforwardChange}
							currentAutoForward={this.props.autoForward}
						/>
					}
					modalID={'SettingsModal'}
					className={'ModalStyle'}
				/>
			</div>
		);
	}
}
//<button className='custom-secondary-button' onClick={this.openSettingsModal}> Settings </button>

export default connect(mapStateToProps)(SettingsButton);