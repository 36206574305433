import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps(store) {
    return ({
        ...store.stats
    });
}

class CallStatsPresentation extends React.Component {
    render() {
        return (
            <div className='CallStateWrapper'>
                <div className='ExpandArrow'>▶</div>
                <div>{'Resolution: ' +
                    this.props.remoteVideoWidth + 'x' +
                    this.props.remoteVideoHeight + ' at ' + 
                    this.props.remoteFrameRate.toFixed(1) + ' fps'}
                </div>
                <div>
                    {'Remote IP: ' + this.props.remoteIpAddress}
                </div>
                <div>
                    {'Local IP: ' + this.props.localIpAddress}
                </div>
                <div>
                    {'Video Codec: ' + this.props.videoCodec} 
                </div>
                <div>
                    {'Audio Codec: ' + this.props.audioCodec}
                </div>
                {(this.props.availableOutgoingBitrate ? <div>
                    {'Outgoing Bandwidth: ' + (this.props.availableOutgoingBitrate/1000000).toFixed(3) + ' Mb/s'}
                </div> : null)}
                {(this.props.remoteOutgoingBitrate ? <div>
                    {'Incoming Bandwidth: ' + (this.props.remoteOutgoingBitrate/1000000).toFixed(3) + 'Mb/s'}
                </div> : null)}
                {(this.props.remoteNetworkType ? <div>
                    {'Remote Network Type: ' + (this.props.remoteNetworkType)}
                </div> : null)}
                <div>
                    {'Frames Received: ' + this.props.framesReceived}
                </div>
                <div>
                     {'Round Trip Time: ' + (this.props.currentRoundTripTime*100).toFixed(0) + 'ms'}
                </div>
                <div>
                    {'Audio Playback Delay: ' + this.props.audioDelay + 'ms'}
                </div>
                <div>
                    {'Video Playback Delay: ' + this.props.videoDelay + 'ms'}
                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps)(CallStatsPresentation);