function user(state = {
	loginStatus: 'LOGGED_OUT',
	LTAUsers: undefined,
	Context: undefined,
	PeerId: undefined,
	Username: undefined,
	Password: undefined,
	error: {
		loginError: undefined
	}}, action) {
	switch (action.type) {
	case 'UPDATE_USER_INFO': {
		return {...state, ...action.payload};
	}
	case 'ON_CONTEXT_CREATED_FULFILLED': {
		if (!action.payload) {
			return;
		}
		return {...state, Context: action.payload.Context, PeerId: action.payload.PeerId};
	}
	case 'ON_GET_LTA_USERS_FULFILLED': {
		if (!action.payload && !action.payload.Lst) {
			return;
		}
		return {...state, LTAUsers: action.payload.Lst};
	}
	// Login
	case 'LOGIN_PENDING' : {
		if (action.payload) {
			return {...state, loginStatus: 'LOGIN_PENDING', Username: action.payload.Username, Password: action.payload.Password};
		} else {
			return {...state, loginStatus: 'LOGIN_PENDING'};
		}
	}
	case 'LOGIN_FULFILLED': {
		return {...state, loginStatus: 'LOGGED_IN', ...action.payload, error: {...state.error, loginError: undefined}};
	}
	case 'LOGIN_REJECTED': {
		return {...state, loginStatus: 'LOGGED_OUT', error: {...state.error, loginError: action.payload.Error}};
	}
	case 'LOGIN_ENDED': {
		return {...state, loginStatus: 'LOGGED_OUT'};
	}
	default : 
		return state;
	}
}

export default user;