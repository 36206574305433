import React from 'react';
import { connect } from 'react-redux';
import Logger from '../../../Utilities/Logging';
import './MainHeader.css';

import { getShows, setCurrentShow } from '../../../Redux/Actions/showActions';
import ShowSelectorPresentation from './ShowSelector/ShowSelectorPresentation';
import EndShowButton from './EndShowButton';
import NewShowButton from './NewShowButton';
import SettingsButton from'./SettingsButton';
import ShowLinkButton from './ShowLinkButton';

function mapStateToProps(store) {
	return ({
		user: {
			DispName: store.user.DispName,
			CompanyName: store.user.CompanyName
		},
		show: {
			currentShow: store.show.currentShow,
			shows: store.show.shows,
		}
	});
}

const INFO = 'Application/Main/MainHeader';

class MainHeader extends React.Component {
	constructor(props) {
		super(props);
		
		this.onSetCurrentShow = this.onSetCurrentShow.bind(this);
	}
	componentDidMount() {
		if (!this.props.show.currentShow) {
			this.props.dispatch(getShows());
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			if (nextProps.currentShow !== this.props.currentShow) {
				this.props.dispatch(getShows());
			}
		}
	}

	onSetCurrentShow(event) {
		const select = event.target;
		const ShowId = Number.parseInt(select.options[select.selectedIndex].value, 10);
		if (!ShowId) {
			Logger.warn(INFO, 'No ShowId found in onSetCurrentShow');
			return;
		}
		const currentShowList = this.props.show.shows.Active.filter(show => show.ShowId === ShowId);
		if (!currentShowList && !currentShowList.length >= 1) {
			Logger.warn(INFO, 'No Show found in onSetCurrentShow');
			return;
		}
		this.props.dispatch(setCurrentShow(currentShowList[0]));
	}

	render() {
		return(
			<div className='Header'>
				<div className='HeaderNameWrapper'>
					<div className='HeaderDispName'>{this.props.user.DispName}</div>
					<div className='HeaderCompanyName'>{this.props.user.CompanyName}</div>
				</div>
				<div className='ShowHeaderWrapper'>
					<NewShowButton/>
					<ShowSelectorPresentation
						onSetCurrentShow={this.onSetCurrentShow}
						currentShowId={(this.props.show.currentShow ? this.props.show.currentShow.ShowId : '')}
						showList={this.props.show.shows.Active}
					/>
					<EndShowButton/>
				</div>
				<div className={"HeaderSettingsButtons"}>
					<SettingsButton/>
					<ShowLinkButton/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(MainHeader);