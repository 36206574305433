
export function attemptOpenModal(id) {
	return {
		type: 'ATTEMPT_MODAL_OPEN',
		payload: id
	};
}

export function attemptCloseModal(id) {
	return {
		type: 'ATTEMPT_MODAL_CLOSE',
		payload: id
	};
}

export function updateCurrentTime() {
	return {
		type: 'UPDATE_CURRENT_TIME',
		payload: Date.now()
	};
}

export function updateAutoForward(value) {
	return {
		type: 'UPDATE_AUTO_FORWARD',
		payload: value
	};
}