import Logger from '../../Utilities/Logging';

const INFO = 'Redux/Middleware/webRTCMiddleware';

class webRTCMiddleware {
	constructor() {
		this.GSocket = undefined;
		this.GnuralRTC = undefined;
		this.RemoteMediaHolder = undefined;
		this.LocalMediaHandler = undefined;

		this.DisconnectAndMovePeer = this.DisconnectAndMovePeer.bind(this);
		this.middleware = this.middleware.bind(this);

		this.InjectWebSocket = this.InjectWebsocket.bind(this);
		this.InjectGnuralRTC = this.InjectGnuralRTC.bind(this);
		this.InjectRemoteMediaHolder = this.InjectRemoteMediaHolder.bind(this);
		this.InjectLocalMediaHandler = this.InjectLocalMediaHandler.bind(this);
		this.getReqId = this.getReqId.bind(this);
	}

	InjectWebsocket(websocket) {
		this.GSocket = websocket;
	}
	InjectGnuralRTC(GnuralRTC) {
		this.GnuralRTC = GnuralRTC;
	}
	InjectRemoteMediaHolder(remoteMediaHolder) {
		this.RemoteMediaHolder = remoteMediaHolder;
	}
	InjectLocalMediaHandler(localMediaHandler) {
		this.LocalMediaHandler = localMediaHandler;
	}

	getReqId(store) {
		if (!store) {
			return '';
		}
		const state = store.getState();
		if (!state || !state.user) {
			return '';
		}
		return 'CIM-UserId:' + state.user.UserId + '-Context:' + state.user.Context +
			'-PeerId:' + state.user.PeerId;
	}

	DisconnectAndMovePeer = (store, next, action) => {
		let guestToUse;
		let newQueueToUse;
		const state = store.getState();
		if (
			!action.payload ||
			!action.payload.guest ||
			!action.payload.guest.CookieGuid ||
			!action.payload.NewQueue
		) {
			Logger.warn(INFO, 'Invalid Parameters to Disconect Show Guest, Checking Locked Guest');
			if (state && state.show && state.show.lockedGuest
				&& state.show.lockedGuest.CookieGuid) {
				guestToUse = state.show.lockedGuest;
				newQueueToUse = state.show.lockedGuest.CallState;
			} else {
				Logger.error(INFO, 'No Locked Guest Found, FAILED TO PROPERLY DISCONNECT, PURGING ALL CONNECTIONS');
				const peers = this.GnuralRTC.Instance.getRemotePeers();
				peers.forEach((peer) => {
					this.GnuralRTC.Instance.removePeerConnection(peer.PeerId, null);
					this.GSocket.websocket.publish('DisconnectPeer', { recipient: peer.PeerId});
				});
				this.GSocket.websocket.publish('leavecontext');
				return;
			}
		} else {
			guestToUse = action.payload.guest;
			newQueueToUse = action.payload.NewQueue;
		}
		if (state.media.remotePeerID) {
			try {
				this.GnuralRTC.Instance.removePeerConnection(state.media.remotePeerID, null);
			} catch (e) {
				Logger.error(INFO, 'Failed to Remove Peer Connection: ', e);
			}
			this.GSocket.websocket.publish('disconnectpeer', {recipient: state.media.remotePeerID});
			this.GSocket.websocket.publish('leavecontext', {});
			this.RemoteMediaHolder.setRemoteStream(undefined);
		}
		this.GSocket.websocket.publish('updateshowguest', {...guestToUse, CallState: newQueueToUse, ReqId: this.getReqId(store)});
		if (next) {
			next({type: action.type});
		}
		return;
	};

	middleware = store => next => action => {
		if (!this.GSocket) {
			Logger.warn(INFO, 'Websocket Not Loaded Yet');
			return next(action);
		}
		if (!this.GnuralRTC) {
			Logger.warn(INFO, 'GnuralRTC Not Loaded Yet');
			return next(action);
		}
		if (!this.RemoteMediaHolder) {
			Logger.warn(INFO, 'RemoteMediaHolder Not Loaded Yet');
			return next(action);
		}
		if (!this.LocalMediaHandler) {
			Logger.warn(INFO, 'LocalMediaHandler Not Loaded Yet');
			return next(action);
		}
		switch (action.type) {
		case 'LOCK_SHOW_GUEST_PENDING': {
			const state = store.getState();
			if (state.show.AttemptingToLockGuest) {
				Logger.error(INFO, 'Already Attempting to Lock a Guest, Cannot Lock Two Guests at Once');
				return;
			}
			this.GSocket.websocket.publish('lockshowguest', action.payload);
			return next(action);
		}
		case 'LOCK_SHOW_GUEST_FULFILLED': {
			if (!action.payload || !action.payload.CookieGuid) {
				Logger.error(INFO, 'Invalid Parameters to Connect to Show Guest');
				return;
			}
			this.GSocket.websocket.publish('updateshowguest', {...action.payload, CallState: 'Screening', ReqId: this.getReqId(store)});
			this.GSocket.websocket.publish('connecttoshowguest', action.payload);
			next(action);
			return;
		}
		case 'ON_CONNECT_TO_SHOW_GUEST_FULFILLED': {
			if (!action || !action.payload ) {
				return next(action);
			}
			const state = store.getState();
			if (!state.show.lockedGuest || !action.payload.CookieGuid || state.show.lockedGuest.CookieGuid !== action.payload.CookieGuid) {
				this.DisconnectAndMovePeer(store, null, {payload: {guest: {...action.payload}, NewQueue: 'Incoming'}});
				return;
			}
			return next(action);
		}
		case 'DISCONNECT_PEER_PENDING': {
			this.DisconnectAndMovePeer(store, next, action);
			return;
		}
		case 'DISCONNECT_PEER_REMOTE': {
			if (!action.payload || !action.payload.ShowGuestGuid) {
				next(action);
				return;
			}
			const state = store.getState();
			if (state.show.selectedGuest.guest.CookieGuid !== action.payload.ShowGuestGuid) {
				next(action);
				return;
			}
			this.DisconnectAndMovePeer(store, null, {payload: {guest: state.show.selectedGuest.guest, NewQueue: 'Incoming'}});
			next({...action, type: 'DISCONNECT_PEER_FULFILLED'});
			return;

		}
		case 'SET_SELECTED_GUEST': {
			const state = store.getState();
			if (state.media.remotePeerID && state.show.selectedGuest.guest && state.show.selectedGuest.guest.CookieGuid) {
				this.DisconnectAndMovePeer(store, null, {payload: {guest: state.show.selectedGuest.guest, NewQueue: 'Incoming'}});
			}
			next(action);
			return;
		}
		case 'UPDATE_SHOW_GUEST_PENDING': {
			if (action.payload && action.payload.NewQueue) {
				this.DisconnectAndMovePeer(store, next, action);
			} else {
				next(action);
			}
			return;
		}
		case 'ON_UPDATE_SHOW_GUEST': {
			if (!action.payload || action.payload.CallState === 'Screening') {
				return next(action);
			}
			const state = store.getState();
			if (state.show && state.show.selectedGuest && state.show.selectedGuest.guest &&
				state.show.selectedGuest.guest.CookieGuid === action.payload.CookieGuid
				&& state.media.remoteMediaStreamID) {
				try {
					this.GnuralRTC.Instance.removePeerConnection(state.media.remotePeerID, null);
				} catch (e) {
					Logger.error(INFO, 'Failed to Remove Peer Connection: ', e);
				}
				this.GSocket.websocket.publish('disconnectpeer', {recipient: action.payload.CookieGuid});
				this.GSocket.websocket.publish('leavecontext', {});
				this.RemoteMediaHolder.setRemoteStream(undefined);
			}
			return next(action);
		}
		case 'ON_REMOTE_STREAM': {
			if (!action.payload) {
				return next(action);
			}
			const state = store.getState();
			if (action.payload && state.show && state.show.lockedGuest) {
				return next(action);
			}
			Logger.error(INFO, 'Received a Remote Stream without Having a Locked Guest, IGNORING STREAM, PURGING ALL CONNECTIONS');
			const peers = this.GnuralRTC.Instance.getRemotePeers();
			peers.forEach((peer) => {
				this.GnuralRTC.Instance.removePeerConnection(peer.PeerId, null);
				this.GSocket.websocket.publish('DisconnectPeer', { recipient: peer.PeerId});
			});
			this.GSocket.websocket.publish('leavecontext');
			return;
		}
		case 'RECONNECT_PEER_PENDING': {
			if (
				!action.payload ||
				!action.payload.Context ||
				!action.payload.Recipient ||
				!action.payload.Sender
			) {
				next({
					type: 'RECONNECT_PEER_REJECTED',
					payload: {error: 'Invalid Parameters for Reconnecting Peer'}
				});
				return;
			}
			const state = store.getState();
			if (state.media.remotePeerID) {
				try {
					this.GnuralRTC.Instance.removePeerConnection(state.media.remotePeerID, null);
				} catch (e) {
					Logger.error(INFO, 'Failed to Remove Peer Connection: ', e);
				}
				this.RemoteMediaHolder.setRemoteStream(undefined);
				this.GSocket.websocket.publish('reconnect', {
					Context: action.payload.Context,
					Recipient: action.payload.Recipient,
					Sender: action.payload.Sender
				});
			}
			next(action);
			return;
		}
		case 'SET_NEW_LOCAL_MEDIA_STREAM': {
			this.RemoteMediaHolder.setRemoteStream(undefined);
			next(action);
			return;
		}
		case 'REFRESH_LOCAL_MEDIA': {
			this.LocalMediaHandler.refreshLocalMedia();
			next(action);
			return;
		}
		case 'CLOSE_SHOW_FULFILLED': {
			if (
				!action.payload ||
				!action.payload.ShowId
			) {
				next(action);
				return;
			}
			const state = store.getState();
			if (state.media.remotePeerID && state.show && state.show.currentShow && state.show.currentShow.ShowId === action.payload.ShowId) {
				Logger.log(INFO, 'ShowID\'s the same, and a remote Peer exists...');
				try {
					this.GnuralRTC.Instance.removePeerConnection(state.media.remotePeerID, null);
				} catch (e) {
					Logger.error(INFO, 'Failed to Remove Peer Connection: ', e);
				}
				if (state.show.lockedGuest && state.show.lockedGuest.CookieGuid) {
					this.GSocket.websocket.publish('disconnectpeer', {recipient: state.show.lockedGuest.CookieGuid});
				}
				this.GSocket.websocket.publish('leavecontext', {});
				this.RemoteMediaHolder.setRemoteStream(undefined);
			}
			next(action);
			return;
		}
		case 'LOGIN_FULFILLED': {
			this.GSocket.websocket.publish('getrelayservers', {});
			return next(action);
		}
		case 'GET_RELAY_SERVERS_FULFILLED': {
			if (!action.payload || !action.payload.Lst) {
				next(action);
				return;
			}
			window.RelayServers = action.payload.Lst;
			next(action);
			return;
		}
		default: {
			next(action);
		}
		}
	};
}

export default new webRTCMiddleware();