import React from 'react';
import LoginPresentation from './LoginPresentation';
import { connect } from 'react-redux';
import { logInUser } from '../../Redux/Actions/loginActions';
import './Login.css';
import HexIcon from '../../Assets/Hex-Icon.png';

function mapStateToProps(store) {
	return {
		user: {
			loginStatus: store.user.loginStatus,
			error: {
				loginError: store.user.error.loginError
			}
		}
	};
}

class Login extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			loginUsername: '',
			loginPassword: '',
		};
		this.onLoginSubmit = this.onLoginSubmit.bind(this);
		this.onLoginUsernameChange = this.onLoginUsernameChange.bind(this);
		this.onLoginPasswordChange = this.onLoginPasswordChange.bind(this);
	}

	onLoginSubmit(event) {
		event.preventDefault();
		this.props.dispatch(logInUser(this.state.loginUsername, this.state.loginPassword));
	}

	onLoginUsernameChange(event) {
		this.setState({
			loginUsername: event.target.value
		});
	}

	onLoginPasswordChange(event) {
		this.setState({
			loginPassword: event.target.value
		});
	}

	render() {
		return ( 
			<div id='LoginWrapperOuter'>
				<div id='LoginWrapperInner' className='CenteredSection'>
					<img src={HexIcon} alt='Gnural Net, Inc. Logo'/>
					<div className='large-label centered-text LoginLabel'> Call-In Manager </div>
					<LoginPresentation {...{
						onLoginSubmit: this.onLoginSubmit,
						onLoginUsernameChange: this.onLoginUsernameChange,
						onLoginPasswordChange: this.onLoginPasswordChange,
						loginUsername: this.state.loginUsername,
						loginPassword: this.state.loginPassword,
						loginError: this.props.user.error.loginError
					}}/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(Login);