import React from 'react';
import GuestDemographicTextFieldPresentation from './GuestDemographicTextFieldPresentation';


function GuestDemographicTextFieldsPresentation(props) {
	let inputList = [];
	if (props.inputList) {
		inputList = props.inputList;
	}
	return (
		<div className='GuestFieldWrapperOuter'>
			{inputList.map(input => {
				if (!input[0] || !input[1] || typeof(input[2]) !== 'string') {
					return null;
				}
				return <GuestDemographicTextFieldPresentation
					key={input[0]}
					id={input[0]}
					label={input[1]}
					value={input[2]}
					onChange={props.onChangeGeneric}
					placeholder={input[3]}
				/>;
			})}
		</div>
	);
}

export default GuestDemographicTextFieldsPresentation;