import GSocket from '../../Utilities/Gnural/websockets';

export function getShows() {
	return (dispatch) => {
		GSocket.websocket.publish('getshows', {});
		dispatch({type: 'GET_SHOW_LIST_PENDING'});
	};
}

export function setCurrentShow(show) {
	return {
		type: 'SET_CURRENT_SHOW',
		payload: show
	};
}

export function createNewShow(show, CIMUser, LTAUser) {
	return (dispatch) => {
		GSocket.websocket.publish('createshow', {
			ShowName: show,
			CreatorUserId: CIMUser.UserId,
			LtaUserId: LTAUser.UserId
		});
		dispatch({type: 'ON_CREATE_SHOW_PENDING'});
	};
}

export function endCurrentShow(show) {
	return (dispatch) => {
		GSocket.websocket.publish('closeshow', show);
		dispatch({
			type: 'CLOSE_SHOW_PENDING',
		});
	};
}

export function getShowGuests(ShowId) {
	return (dispatch) => {
		GSocket.websocket.publish('getshowguests', { ShowId });
		dispatch({type: 'GET_SHOW_GUESTS_PENDING'});
	};
}

export function getSendPositionUpdates(ShowId) {
	return (dispatch) => {
		GSocket.websocket.publish('getshowguests', { ShowId });
		dispatch({type: 'GET_SEND_POSITION_UPDATES_PENDING'});
	};
}

export function setSelectedGuestSite(GuestSite)
{
	return {
		type: 'SET_SELECTED_GUEST_SITE',
		payload: GuestSite
	};
}

export function setEmbedUrl(EmbedUrl)
{
	return {
		type: 'SET_EMBED_URL',
		payload: EmbedUrl
	};
}