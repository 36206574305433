import React from 'react';

function GuestBrowserInfoPresentation(props) {
	function precomputedOnUpdateSelectGeneric(selectType) {
		return (event) => {props.onUpdateSelectGeneric(selectType, event);};
	}
	return (
		<div id='GuestBrowserInfoContainer'>
			<div> {props.OSVersion} </div>
			<div> {props.BrowserName} </div>
			<div> {props.BrowserVersion} </div>
			<select value={(props.currentVideoInput ? props.currentVideoInput : undefined)} onChange={precomputedOnUpdateSelectGeneric('videoInputList')}>
				<option value={undefined} disabled>- Select Video Input Device -</option>
				{props.videoInputList.map((input, index) => {
					return <option value={input} key={'Guest-Settings-Video-Input-' + input + '-' + index}>{input}</option>;
				})}
			</select>
			<select value={props.currentVideoResolution ? props.currentVideoResolution : undefined} onChange={precomputedOnUpdateSelectGeneric('videoResolutionList')}>
				<option value={undefined} disabled>- Select Video Resolution -</option>
				{props.videoResolutionList.map(resolution => {
					return <option value={resolution} key={'Guest-Settings-Resolution-' + resolution}>{resolution}</option>;
				})}
			</select>
			<select value={(props.currentAudioInput ? props.currentAudioInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioInputList')}>]
				<option value={undefined} disabled>- Select Audio Input Device -</option>
				{props.audioInputList.map((input, index) => {
					return <option value={input} key={'Guest-Settings-Audio-Input-' + input + '-' + index}>{input}</option>;
				})}
			</select>
			<select value={(props.currentAudioOutput ? props.currentAudioOutput : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioOutputList')}>
				<option value={undefined} disabled>- Select Audio Output Device -</option>
				{props.audioOutputList.map((output, index) => {
					return <option value={output} key={'Guest-Settings-Audio-Output-' + output + '-' + index}>{output}</option>;
				})}
			</select>
			<button className='BrowserInfoRefreshButton' onClick={props.onReconnectPeer}> Refresh </button>
		</div>
	);
}

export default GuestBrowserInfoPresentation;
