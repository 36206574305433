import React from 'react';
import { connect } from 'react-redux';
import Logger from '../../Utilities/Logging';

import {attemptCloseModal } from '../../Redux/Actions/userinterfaceActions';

const INFO = 'Application/Modal';

function mapStateToProps(store) {
	return ({
		openModalID: store.userinterface.openModalID
	});
}

class Modal extends React.Component {
	constructor(props) {
		super(props);

		this.getBody = this.getBody.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (!nextProps.modalID) {
			Logger.error(INFO, 'Component is Received Props without a modalID!');
		}
	}

	componentWillUnmount() {
		if (this.props.openModalID === this.props.modalID) {
			this.props.dispatch(attemptCloseModal(this.props.modalID));
		}
	}

	closeModal(isSuccess=false){return () => {
		if (this.props.openModalID === this.props.modalID) {
			this.props.dispatch(attemptCloseModal(this.props.modalID));
			if (isSuccess && this.props.onSuccess) {
				this.props.onSuccess();
			} else if (this.props.onFailure) {
				this.props.onFailure();
			}
		}
	};}
	getBody() {
		if (this.props.openModalID === this.props.modalID) {
			return (
				<div className='ModalOverlay'>
					<div id={this.props.modalID} className={(this.props.className ? this.props.className : 'ModalWrapper')}>
						<div className='ModalBody'>
							{this.props.modalContent}
						</div>
						<div className='ModalFooter'>
							{(this.props.onSuccess ? <div className='ModalOk' onClick={this.closeModal(true)}><div className='centered-text'>{(this.props.OkMessage ? this.props.OkMessage : 'Ok')}</div></div> : null)}
							<div className='ModalClose' onClick={this.closeModal(false)}><div className='centered-text'>Close</div></div>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		return this.getBody();
	}
}

export default connect(mapStateToProps)(Modal);