import React from 'react';
import Main from './Main';
import Login from './Login';
import { connect } from 'react-redux';
import '../Utilities/Gnural/GnuralRTC';
import '../Utilities/Gnural/LocalMediaHandler/index';
import './App.css';
import { AcquireBrowserInfo} from '../Utilities/Gnural/SessionInformation';
import { setBrowserInformation} from '../Redux/Actions/sessionActions';

function mapStateToProps(store) {
	return {
		websocket: {
			ConnectionStatus: store.websocket.ConnectionStatus
		},
		user: {
			loginStatus: store.user.loginStatus
		}
	};
}

class App extends React.Component {

	componentDidMount() {
		this.props.dispatch(setBrowserInformation(AcquireBrowserInfo()));
	}

	displayProperScreen() {
		if (this.props.websocket.ConnectionStatus !== 'CONNECTED') {
			return <div className='ConnectionFailure CenteredSection'><div>- Connecting to Signal Server -</div></div>;
		}
		if (this.props.user.loginStatus === 'LOGGED_IN') {
			return <Main/>;
		} else {
			return <Login/>;
		}
	}

	render() {
		return this.displayProperScreen(); 
	}
}

export default connect(mapStateToProps)(App);
