// In Case we want more Complex Logging Logic, such as sending Logs somewhere in realtime....
const Logger = {};

Logger.shouldPrintDebug = true;

Logger.log = (INFO, ...body) => {
	if (!INFO || typeof(INFO) !== 'string') {
		throw new Error('Invalid Argument');
	}
	console.log('<'+INFO+'>: ', ...body);
};

Logger.debug = (INFO, ...body) => {
	if (!INFO || typeof(INFO) !== 'string') {
		throw new Error('Invalid Argument');
	}
	if (Logger.shouldPrintDebug) {
		console.log('<'+INFO+'>: ', ...body);
	}
};

Logger.warn = (INFO, ...body) => {
	if (!INFO || typeof(INFO) !== 'string') {
		throw new Error('Invalid Argument');
	}
	console.warn('<'+INFO+'>: ', ...body);
};

Logger.error = (INFO, ...body) => {
	if (!INFO || typeof(INFO) !== 'string') {
		throw new Error('Invalid Argument');
	}
	console.error('<'+INFO+'>: ', ...body);
};

export default Logger;