import React from 'react';

function GuestCardRatingPresentation(props) {
	const returnStars = [];
	for (let index = 0; index < props.rating; index++) {
		returnStars.push(<div className='RatingStarWrapper' key={props.cookieGuid + '-ActiveStar-' + index}><div className='RatingStar ActiveStar'/></div>);
	}
	while (returnStars.length < 5) {
		returnStars.push(<div className='RatingStarWrapper' key={props.cookieGuid + '-InactiveStar-' + (returnStars.length-1)}><div className='RatingStar InactiveStar'/></div>);
	}
	return (
		<div className='RatingStarEntire'>
			{returnStars}
		</div>
	); 
}

export default GuestCardRatingPresentation;