import Logger from '../../Utilities/Logging';

const INFO = 'Redux/Middleware/autoForwardMiddleware';

class autoForwardMiddleware {
	constructor() {
		this.GSocket = undefined;

		this.InjectWebsocket = this.InjectWebsocket.bind(this);
		this.middleware = this.middleware.bind(this);
		this.getReqId = this.getReqId.bind(this);
	}

	InjectWebsocket(websocket) {
		this.GSocket = websocket;
	}

	getReqId(store) {
		if (!store) {
			return '';
		}
		const state = store.getState();
		if (!state || !state.user) {
			return '';
		}
		return 'CIM-UserId:' + state.user.UserId + '-Context:' + state.user.Context +
			'-PeerId:' + state.user.PeerId;
	}

	middleware = store => next => action => {
		if (!this.GSocket) {
			Logger.warn(INFO, 'Websocket Not Loaded Yet');
			return next(action);
		}
		if (action.type !== 'ON_ENTER_SHOW' && action.type !=='ON_UPDATE_SHOW_GUEST') {
			return next(action);
		}
		const state = store.getState();
		if (!state.userinterface.autoForward) {
			return next(action);
		}
		if (['Blocked', 'Acitve', 'OnAir', 'OffAir', 'Assigned', 'Accepted'].includes(action.payload.CallState)) {
			return next(action);
		}
		if (!state.show.currentShow || action.payload.ShowId !== state.show.currentShow.ShowId) {
			return next(action);
		}
		this.GSocket.websocket.publish('updateshowguest', {...action.payload, CallState: 'Accepted', ReqId: this.getReqId(store)});
	};
}



export default new autoForwardMiddleware();