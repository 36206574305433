import { combineReducers } from 'redux';
import user from './user';
import websocket from './websocket';
import show from './show';
import userinterface from './userinterface';
import media from './media';
import stats from './stats';
import sessioninformation from './sessionInformation';

const combinedReducer = combineReducers({ user, websocket, show, userinterface, media, stats, sessioninformation});

export default combinedReducer;
