import React from 'react';

function GuestDemographicGroupPresentation(props) {
	function generateOptionArray() {
		let returnArray = [];
		for (let index = 0; index < props.numberOfOptions; index++) {
			returnArray.push(
				<div
					className={'groupOption' + (props.currentGroup === index ? ' groupOptionActive' : '')}
					key={'group-option-'+index}
					value={index}
					onClick={props.onUpdateGroup}
				/>
			);
		}
		return returnArray;
	}
	return (
		<div className='groupOptionWrapper' >
			{generateOptionArray()}
		</div>
	);
}

export default GuestDemographicGroupPresentation;