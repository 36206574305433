import store from '../../Redux/store';
import Logger from '../Logging';

const INFO = 'Utilities/Gnural/ReduxConnector';

class ReduxConnector {
	constructor(store) {
		this.store = store;
		this.currentStore = store.getState();
		this.callbacks = [];

		this.onStoreUpdate = this.onStoreUpdate.bind(this);
		this.connect = this.connect.bind(this);

		store.subscribe(this.onStoreUpdate);
	}

	connect(neededStoreVariables, updateFunction) {
		if (!neededStoreVariables ||
			typeof(neededStoreVariables) !== 'function' ||
			!updateFunction || typeof(updateFunction) !== 'function'
		) {
			return;
		}
		try {
			const result = neededStoreVariables(this.currentStore);
			updateFunction(result);
			this.callbacks.push([neededStoreVariables, updateFunction, result]);
		} catch (e) {
			Logger.error(INFO, 'Failed to Connect', e);
		}
	}

	onStoreUpdate() {
		const newStore = store.getState();
		
		let result;
		this.callbacks.forEach( (callback) => {
			try {
				result = callback[0](newStore);
				if (result === callback[2]) {
					return;
				}
				callback[2] = result;
				callback[1](result);
			} catch (e) {
				Logger.error(INFO, 'Failed to Update Function', e);
			}
		});
	}

	dispatch(dispatch) {
		this.store.dispatch(dispatch);
	}
}

const connector = new ReduxConnector(store);

export default connector;