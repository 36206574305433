import GnuralRTC from '../GnuralRTC';
import ReduxConnector from '../ReduxConnector';

class WebRTCStats {
	constructor() {
		this.props = { remoteMediaStreamID: '', remotePeerID: '' };

		this.updateProperties = this.updateProperties.bind(this);
		this.startFetchingStats = this.startFetchingStats.bind(this);
		this.interval = setInterval(this.startFetchingStats, 4000);
	}

	async updateProperties(newProperties) {
		if (newProperties.remoteMediaStreamID === this.props.remoteMediaStreamID
			&& newProperties.remotePeerID === this.props.remotePeerID) {
			return;
		}
		this.props.remoteMediaStreamID = newProperties.remoteMediaStreamID;
		this.props.remotePeerID = newProperties.remotePeerID || '';
	}

	async startFetchingStats() {
		if (!this.props.remoteMediaStreamID || !this.props.remotePeerID) {
			return;
		}
		if (!GnuralRTC.Instance.PeerConnections || !GnuralRTC.Instance.PeerConnections[this.props.remotePeerID]
			|| !GnuralRTC.Instance.PeerConnections[this.props.remotePeerID]?.Connection) {
			return;
		}
		let statsGoogle = {};
		try {
			// Legacy
			/*try {
				statsGoogle = await new Promise(async (resolve, reject)=> {
					try {
						await GnuralRTC.Instance.PeerConnections[this.props.remotePeerID]?.Connection?.getStats((report) => {
							if (!report) {
								reject();
							}
							const stats = report.result();
							const finalStats = {};
							stats.forEach(stat => {
								finalStats[stat.id] = {};
								stat.names().forEach(name => {
									finalStats[stat.id][name] = stat.stat(name);
								});
							});
							resolve(finalStats);
						});
					} catch (e) {
						reject();
					}
				});
			} catch (e) {}*/
			const statsSpecResults = await GnuralRTC.Instance.PeerConnections[this.props.remotePeerID].Connection?.getStats() ?? {};
			const statsSpec = {};
			statsSpecResults.forEach(stat => {
				if (!stat.id) {
					return;
				}
				statsSpec[stat.id] = stat;
			});
			const finalStats = {...statsGoogle, ...statsSpec};
			ReduxConnector.dispatch({
				type: 'ON_WEBRTC_STATS',
				payload: finalStats
			});
		} catch (e) {
		}
	}
}

function neededStoreVariables(store) {
	return store.media;
}

const WebRTCStatsInstance = new WebRTCStats();

ReduxConnector.connect(neededStoreVariables, WebRTCStatsInstance.updateProperties);

export default WebRTCStatsInstance;