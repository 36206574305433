import React from 'react';

function NewShowButtonPresentation(props) {
	let showList = [];
	let LTAUsers = [];
	if (props.showList) {
		showList = props.showList;
	}
	if (props.LTAUsers) {
		LTAUsers = props.LTAUsers;
	}
	return(
		<div>
			<input
				type='text'
				list='new-show-list'
				onChange={props.onNewShowUpdate}
				value={props.currentNewShowName}
				placeholder={'Enter a New Show Name...'}
			/>
			<datalist id='new-show-list'>
				{showList.map(show => {
					return (
						<option key={'new-show-'+show} value={show}>{show}</option>
					);
				})}
			</datalist>
			<select value={(props.currentLTAUser || {}).UserId} onChange={props.onNewLTAUser}>
				<option value='' disabled> - LiveToAir Operator Account - </option>
				{LTAUsers.map(LTAUser => {
					return (
						<option key={'new-show-user-'+LTAUser.UserId} value={LTAUser.UserId}>{LTAUser.DispName}</option>
					);
				})}
			</select>
		</div>
	);
}

export default NewShowButtonPresentation;