import React from 'react';

function MoveGuestPresentation(props) {
	const currentPosition = (props.selectedGuest ? props.selectedGuest.CallState : undefined);
	function preparedMoveSelectGuest(position) {
		if (!props.selectedGuest || position === props.selectedGuest.CallState) {
			return;
		}
		return () => {return props.onMoveSelectedGuest(position);};
	}
	return (
		<div className='MoveGuestButtonContainer'>
			<div className='MoveGuestButtonContainerInner'>
				{(props.remotePeerID ?
					<div className='MoveGuestButtonOuter DisconnectButton ValidMoveGuestButton centered-text' onClick={props.disconnectSelectedGuest}><div className="MoveGuestButtonInner"><div>Disconnect</div></div></div> :
					<div className={'MoveGuestButtonOuter ConnectButton centered-text' + (props.canConnectToGuest ? ' ValidMoveGuestButton' : ' InactiveMoveGuestButton')} onClick={props.connectToSelectedGuest}><div className="MoveGuestButtonInner"><div>Connect</div></div></div>
				)}
			</div>
			<div className='MoveGuestButtonContainerInner'>
				<div
					className={
						'MoveGuestButtonOuter centered-text' + 
						(props.canMoveGuest && currentPosition !== 'Incoming' ? ' ValidMoveGuestButton' : ' InactiveMoveGuestButton')
					}
					onClick={
						preparedMoveSelectGuest('Incoming')
					}><div className="MoveGuestButtonInner"><div>To Inbound</div></div></div>

				<div
					className={
						'MoveGuestButtonOuter centered-text' + 
						(props.canMoveGuest && currentPosition !== 'Accepted' ? ' ValidMoveGuestButton' : ' InactiveMoveGuestButton')
					}
					onClick={
						preparedMoveSelectGuest('Accepted')
					}><div className="MoveGuestButtonInner"><div>To Studio</div></div></div>

				<div
					className={
						'MoveGuestButtonOuter centered-text' +
						(props.canMoveGuest && currentPosition !== 'Finished' ? ' ValidMoveGuestButton' : ' InactiveMoveGuestButton')
					}
					onClick={
						preparedMoveSelectGuest('Finished')
					}><div className="MoveGuestButtonInner"><div>Complete</div></div></div>

				<div
					className={
						'MoveGuestButtonOuter centered-text' +
						(props.canMoveGuest && currentPosition !== 'Blocked' ? ' ValidMoveGuestButton' : ' InactiveMoveGuestButton')
					}
					onClick={
						preparedMoveSelectGuest('Blocked')
					}><div className="MoveGuestButtonInner"><div>Block </div></div></div>
			</div>
		</div>
	);
}

export default MoveGuestPresentation;