// Helper Functions

const defaultSelectedGuest = {
	guest: {},
	browserInfo: {
		CameraList: [],
		CameraResList: [],
		MicList: [],
		SpkrList: []
	},
	showHistory: []
};

function reformatDate(date) {
	const validCharacters = ['0','1','2','3','4','5','6','7','8','9','-'];
	let returnDate = '';
	for (let index = 0; index < date.length ; index++) {
		if (date[index] in validCharacters || date[index] === '-') {
			returnDate += date[index];
		}
	}
	return new Date(Number.parseInt(returnDate,10));
}

function guestComparer(guestA, guestB) {
	if (!guestA || !guestB) {
		return 0;
	}
	if (guestA.Active !== guestB.Active) {
		return (guestA.Active ? -1 : 1);
	}
	if (guestA.Priority !== guestB.Priority) {
		return guestB.Priority - guestA.Priority;
	}
	return guestA.JavascriptDate - guestB.JavascriptDate;
}

function processQueue(name, newGuest, state) {
	if (!newGuest) {
		return;
	}
	const TempArray = state.guests[name[0]].filter(guest => guest.CookieGuid !== newGuest.CookieGuid);
	if (name.includes(newGuest.CallState)) {
		TempArray.push(newGuest);
		return TempArray.sort(guestComparer);
	}
	return TempArray;
}

//Reducer 
function show(state={
	guestSiteList: ["callin.studio", "global.callin.studio"],
	selectedGuestSite: "callin.studio",
	embedUrl: "",
	currentShow: undefined,
	shows: {state: 'UNINITIALIZED', Active: [], Inactive: []},
	selectedGuest: defaultSelectedGuest,
	lockedGuest: undefined,
	guests: {
		state: 'UNINITIALIZED',
		Incoming: [],
		Blocked: [],
		Accepted: [],
		Active: [],
		Finished: []
	},
	AttemptingToLockGuest: false,
	error: {
		getShowError: undefined,
		getGuestsError: undefined
	}}, action) {
	switch(action.type) {
	// Get/Set Shows
	case 'SET_CURRENT_SHOW': {
		return {...state, currentShow: action.payload, selectedGuest: defaultSelectedGuest};
	}
	case 'GET_SHOWS_PENDING': {
		return {...state, shows: {...state.shows, state: 'PENDING'}};
	}
	case 'GET_SHOWS_FULFILLED': {

		let ActiveShows;
		let InactiveShows;
		let currentShow;
		if (action.payload.Lst) {
			ActiveShows = action.payload.Lst.filter(show => show.Active);
			InactiveShows = action.payload.Lst.filter(show => !show.Active);
		} else {
			ActiveShows = [];
			InactiveShows = [];
		}

		// Might need to go over this logic
		if (ActiveShows.length >= 1) {
			if (!state.currentShow) {
				currentShow = ActiveShows[0];
			} else if (!ActiveShows.reduce((max, cur) => {return (max || (cur.ShowId === state.currentShow.ShowId));}) ) {
				currentShow = ActiveShows[0];
			} else {
				currentShow = state.currentShow;
			}
		} 

		return {...state, shows: { state: 'FULFILLED', Active: ActiveShows, Inactive: InactiveShows}, currentShow};
	}
	case 'LOCK_SHOW_GUEST_PENDING': {
		return {...state, AttemptingToLockGuest: true};
	}
	case 'LOCK_SHOW_GUEST_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {...state, lockedGuest: action.payload, AttemptingToLockGuest: false};
	}
	case 'LOCK_SHOW_GUEST_FAILED': {
		return {...state, AttemptingToLockGuest: false};
	}
	case 'LOCK_SHOW_GUEST_REMOVE': {
		return {...state, lockedGuest: undefined};
	}
	case 'DISCONNECT_PEER_FULFILLED': {
		return {...state, lockedGuest: undefined};
	}
	case 'GET_SHOWS_REJECTED': {
		return {...state, shows: {...state.shows, state: 'REJECTED'}};
	}
	case 'CLOSE_SHOW_FULFILLED': {
		if (!action.payload || !action.payload.ShowId) {
			return;
		}
		const newActiveShowList = state.shows.Active.filter(show => {
			return action.payload.ShowId !== show.ShowId;
		});
		let newCurrentShow = state.currentShow;
		let guests = state.guests;
		let selectedGuest = state.selectedGuest;
		if (state.currentShow && state.currentShow.ShowId === action.payload.ShowId) {
			if (newActiveShowList.length >= 1) {
				newCurrentShow = newActiveShowList[0];
			} else {
				newCurrentShow = undefined;
			}
			guests = {
				state: 'UNINITIALIZED',
				Incoming: [],
				Blocked: [],
				Accepted: [],
				Active: [],
				Finished: []
			};
			selectedGuest = defaultSelectedGuest;

		}
		return {...state, shows: {...state.shows, Active: newActiveShowList}, currentShow: newCurrentShow, guests, selectedGuest};
	}
	case 'ON_CREATE_SHOW_FULFILLED': {
		if (!action.payload) {
			return;
		}
		if (state.shows.Active.reduce((exists, show) => exists || show.ShowId === action.payload.ShowId, false)) {
			return state;
		}
		const newActiveList = state.shows.Active.concat([action.payload]);
		let newCurrentShow = state.currentShow;
		if (!state.currentShow) {
			newCurrentShow = action.payload;
		}
		return {...state, shows: {...state.shows, Active: newActiveList}, currentShow: newCurrentShow};
	}
	// Get ShowGuests
	case 'SET_SELECTED_GUEST': {
		if (
			state &&
			state.selectedGuest &&
			state.selectedGuest.guest &&
			action.payload && 
			action.payload.CookieGuid === state.selectedGuest.guest.CookieGuid) {
			return state;
		}
		return {
			...state,
			selectedGuest: {
				guest: action.payload,
				browserInfo: {
					CameraList: [],
					CameraResList: [],
					MicList: [],
					SpkrList: []
				},
				showHistory: []
			}
		};
	}
	case 'GET_SHOW_GUEST_HISTORY_FULFILLED': {
		if (!action.payload ||
			!action.payload.Lst ||
			!action.payload.Lst[0] ||
			!state.selectedGuest || 
			!state.selectedGuest.guest ||
			!state.selectedGuest.guest.CookieGuid ||
			!action.payload.Lst[0].CookieGuid === state.selectedGuest.guest.CookieGuid) {
			return state;
		}
		const showHistory = action.payload.Lst.map(guest => {
			guest.JavascriptDate = reformatDate(guest.CallTime);
			return guest;
		});
		return {...state, selectedGuest: {...state.selectedGuest, showHistory}};
	}
	case 'REQUEST_BROWSER_INFO_FULFILLED': {
		if (!action.payload ||action.payload.PeerGuid !== state.selectedGuest.guest.CookieGuid) {
			return state;
		}
		return {...state, selectedGuest: {...state.selectedGuest, browserInfo: {...action.payload}}};
	}
	case 'GET_GUESTS_PENDING': {
		return {...state, guests: {...state.guests, state: 'PENDING'}};
	}
	case 'GET_GUESTS_FULFILLED': {
		if (!action.payload || !action.payload.Lst) {
			return state;
		}
		action.payload.Lst.forEach(guest => {
			guest.JavascriptDate = reformatDate(guest.CallTime);
		});
		return {...state, guests: {
			Incoming: action.payload.Lst.filter(guest => guest.CallState === 'Incoming').sort(guestComparer),
			Blocked: action.payload.Lst.filter(guest => guest.CallState === 'Blocked').sort(guestComparer),
			Accepted: action.payload.Lst.filter(guest => guest.CallState === 'Accepted').sort(guestComparer),
			Active: action.payload.Lst.filter(
				guest => guest.CallState === 'Active'
			).concat(action.payload.Lst.filter(
				guest => guest.CallState === 'OnAir'
			).concat(action.payload.Lst.filter(
				guest => guest.CallState === 'OffAir' 
			)).concat(action.payload.Lst.filter(
				guest => guest.CallState === 'Assigned'
			))).sort(guestComparer),
			Finished: action.payload.Lst.filter(guest => guest.CallState === 'Finished').sort(guestComparer)
		}};
	}
	case 'GET_GUESTS_REJECTED': {
		return {...state, guests: {...state.guests, state: 'REJECTED'}, error: {...state.error, getGuestsError: action.payload}};
	}
	case 'ON_ENTER_SHOW':
	case 'ON_UPDATE_SHOW_GUEST': {
		if ((!action.payload && !action.payload.CookieGuid) ||
			!state.currentShow ||
			(state.currentShow.ShowId !== action.payload.ShowId)
		) {
			return state;
		}
		action.payload.JavascriptDate = reformatDate(action.payload.CallTime);
		let newSelectedGuest = state.selectedGuest.guest;
		let lastQueue;
		if (newSelectedGuest){
			lastQueue = ((newSelectedGuest.CallState === 'OnAir' || newSelectedGuest.CallState === 'OffAir' || newSelectedGuest.CallState === 'Assigned') ?
				'Active' :
				newSelectedGuest.CallState
			);

			if (lastQueue === 'Screening') {
				lastQueue = (state.lockedGuest && state.lockedGuest.CallState ? state.lockedGuest.CallState : 'Incoming');
			}
		} else {
			lastQueue = undefined;
		}

		const processedQueues = {
			Incoming: processQueue(['Incoming'], action.payload, state),
			Blocked: processQueue(['Blocked'], action.payload, state),
			Accepted: processQueue(['Accepted'], action.payload, state),
			Active: processQueue(['Active', 'Assigned', 'OnAir', 'OffAir'], action.payload, state),
			Finished: processQueue(['Finished'], action.payload, state)
		};
		let newHistory = state.selectedGuest.showHistory;
		let newBrowserInfo = state.selectedGuest.browserInfo;
		if (newSelectedGuest && newSelectedGuest.CookieGuid === action.payload.CookieGuid) {
			if (
				newSelectedGuest.CallState === action.payload.CallState ||
				(action.payload.CallState === 'Screening' && state.lockedGuest && action.payload.CookieGuid === state.lockedGuest.CookieGuid) ||
				(newSelectedGuest.CallState === 'Screening' && newSelectedGuest.CallState === 'Incoming')
			) {
				newSelectedGuest = action.payload;
			} else if (processedQueues[lastQueue].length >= 1) {
				if (newSelectedGuest.CookieGuid !== processedQueues[lastQueue][0].CookieGuid) {
					newHistory = [];
					newBrowserInfo = { CameraList: [], CameraResList: [], MicList: [], SpkrList: []};
				}
				newSelectedGuest = processedQueues[lastQueue][0];
			} else if (processedQueues.Incoming.length >= 1) {
				if (newSelectedGuest.CookieGuid !== processedQueues.Incoming[0].CookieGuid) {
					newHistory = [];
					newBrowserInfo = { CameraList: [], CameraResList: [], MicList: [], SpkrList: []};
				}
				newSelectedGuest = processedQueues.Incoming[0];
			} else {
				newSelectedGuest = {};
				newHistory = [];
				newBrowserInfo = { CameraList: [], CameraResList: [], MicList: [], SpkrList: []};
			}
		}
		let newLockedGuest = state.lockedGuest;
		if (newSelectedGuest.CallState !== 'Screening') {
			newLockedGuest = undefined;
		}
		return {...state, lockedGuest: newLockedGuest, selectedGuest: {showHistory: newHistory, guest: newSelectedGuest, browserInfo: newBrowserInfo}, guests: processedQueues};
	}
	/*case 'ON_ENTER_SHOW': {
		if ((!action.payload && !action.payload.CookieGuid) ||
			!state.currentShow ||
			(state.currentShow.ShowId !== action.payload.ShowId)
		) {
			return state;
		}
		action.payload.JavascriptDate = reformatDate(action.payload.CallTime);
		let newSelectedGuest = state.selectedGuest.guest;
		let newShowHistory = state.selectedGuest.showHistory;
		let newBrowserInfo = state.selectedGuest.browserInfo;
		if (!newSelectedGuest) {
			newSelectedGuest = action.payload;
			newShowHistory = [];
			newBrowserInfo = { CameraList: [], CameraResList: [], MicList: [], SpkrList: []};
		}
		return {...state, selectedGuest: {guest: newSelectedGuest, showHistory: newShowHistory, browserInfo: newBrowserInfo}, guests: {...state.guests, Incoming: processQueue(['Incoming'], action.payload, state)}};
	}*/
	case 'WEBSOCKET_CONNECTION_CLOSED': {
		return {
			...state,
			currentShow: undefined,
			shows: {state: 'UNINITIALIZED', Active: [], Inactive: []},
			selectedGuest: defaultSelectedGuest,
			lockedGuest: undefined,
			guests: {
				state: 'UNINITIALIZED',
				Incoming: [],
				Blocked: [],
				Accepted: [],
				Active: [],
				Finished: []
			},
			error: {
				getShowError: undefined,
				getGuestsError: undefined
			}
		};
	}
	case 'SET_SELECTED_GUEST_SITE': {
		if (!action.payload || !(state.guestSiteList || []).includes(action.payload))
		{
			return state;
		}
		return {
			...state,
			selectedGuestSite: action.payload
		}
	}

	case 'SET_EMBED_URL': {
		return {
			...state,
			embedUrl: action.payload || ""
		};
	}
	default: 
		return state; 
	}
}

export default show;