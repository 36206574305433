import React from 'react';

function LoginPresentation(props) {
	return (
		<form onSubmit={props.onLoginSubmit}>
			<input
				type='text'
				value={props.loginUsername}
				onChange={props.onLoginUsernameChange}
				placeholder={'Enter your Username...'}
				autoComplete='username'
			/>
			<input
				type='password'
				value={props.loginPassword}
				onChange={props.onLoginPasswordChange}
				placeholder={'Enter your Password...'}
				autoComplete='current-password'
			/>
			<div id='LoginError' className='error-font centered-text'>{props.loginError}</div>
			<button className='custom-active-button' type='submit'> Login </button>
		</form>
	);
}

export default LoginPresentation;