import React from 'react';
import { connect } from 'react-redux';
import { setNewMediaDevice, refreshLocalMedia } from '../../../../Redux/Actions/mediaActions';
import { attemptOpenModal } from '../../../../Redux/Actions/userinterfaceActions';

import ShowLinkButtonPresentation from './ShowLinkButtonPresentation';
import Modal from '../../../Modal';
import { setSelectedGuestSite, setEmbedUrl } from '../../../../Redux/Actions/showActions';

function mapStateToProps(store) {
	return ({
        guestSiteList: store.show.guestSiteList,
        selectedGuestSite: store.show.selectedGuestSite,
        embedUrl: store.show.embedUrl,
        currentShow: store.show.currentShow
	});
}

class ShowLinkButton extends React.Component {
	constructor(props) {
		super(props);
        // Do stuff with binding
        this.openShowLinkModal = this.openShowLinkModal.bind(this);
        this.onUpdateSelectedGuestSite = this.onUpdateSelectedGuestSite.bind(this);
        this.onUpdateEmbedUrl = this.onUpdateEmbedUrl.bind(this);
	}

	onRefreshLocalMedia() {
		this.props.dispatch(refreshLocalMedia());
	}

	onUpdateSelectGeneric(selectType, event) {
		if (!event || !event.target || !selectType) {
			return;
		}
		const currentlySelectedObject = event.target.options[event.target.selectedIndex].value;
		if (!currentlySelectedObject) {
			return;
		}
		const desiredItem = this.props[selectType].filter(objects => {
			return objects.deviceId === currentlySelectedObject;
		});
		if (!desiredItem[0]) {
			return;
		}
		this.props.dispatch(setNewMediaDevice(selectType, desiredItem[0]));
	}

	openShowLinkModal() {
		this.props.dispatch(attemptOpenModal('ShowLinkModal'));
    }
    
    onUpdateSelectedGuestSite(event)
    {
        if (!event || !event.target)
        {
            return;
        }
        const currentlySelectedObject = event.target.options[event.target.selectedIndex].value;
        this.props.dispatch(setSelectedGuestSite(currentlySelectedObject));
    }

    onUpdateEmbedUrl(event)
    {
        if (!event || !event.target)
        {
            return;
        }
        this.props.dispatch(setEmbedUrl(event.target.value || ""));
    }

	render() {
		return (
			<div className='SettingsButtonHeaderWrapper'>
				<div className='SettingsButton' onClick={this.openShowLinkModal}>
                   <div>Link</div> 
				</div>
				<Modal
					modalContent={
						<ShowLinkButtonPresentation
                            selectedGuestSite={this.props.selectedGuestSite}
                            guestSiteList={this.props.guestSiteList}
                            embedUrl={this.props.embedUrl}
                            onUpdateSelectedGuestSite={this.onUpdateSelectedGuestSite}
                            onUpdateEmbedUrl={this.onUpdateEmbedUrl}
                            currentShow={this.props.currentShow}
						/>
					}
					modalID={'ShowLinkModal'}
					className={'ModalStyle'}
				/>
			</div>
		);
	}
}
//<button className='custom-secondary-button' onClick={this.openSettingsModal}> Settings </button>

export default connect(mapStateToProps)(ShowLinkButton);