import React from 'react';

import MoveGuestPresentation from './MoveGuestPresentation';
import GuestDemographicSection from './GuestDemographicSection';
import GuestMediaSection from './GuestMediaSection';

import { connect } from 'react-redux';
import { moveGuestToQueue, getShowGuestLock, disconnectShowGuest,} from '../../../Redux/Actions/guestActions';

import './ModifyGuestSection.css';

function mapStateToProps(store) {
	return ({
		selectedGuest: store.show.selectedGuest.guest,
		lockedGuest: store.show.lockedGuest,
		showHistory: store.show.selectedGuest.showHistory,
		remotePeerID: store.media.remotePeerID,
		UserId: store.user.UserId || '',
		Context: store.user.Context || '',
		PeerId: store.user.PeerId || ''
	});
}

class ModifyGuestSection extends React.Component {
	constructor(props) {
		super(props);

		this.onMoveSelectedGuest = this.onMoveSelectedGuest.bind(this);
		this.onSetActiveTab = this.onSetActiveTab.bind(this);
		this.displayProperTabbedSection = this.displayProperTabbedSection.bind(this);
		this.onConnectToSelectedGuest = this.onConnectToSelectedGuest.bind(this);
		this.onDisconnectSelectedGuest = this.onDisconnectSelectedGuest.bind(this);

		this.state = {
			activeTab: ['Demographics', 'Demographics'],
			canConnectToGuest: false,
			canMoveGuest: false
		};
	}

	onMoveSelectedGuest(position) {
		if (!position || typeof(position) !== 'string' || !this.state.canMoveGuest || this.props.selectedGuest.CallState === position) {
			return;
		}

		this.props.dispatch(moveGuestToQueue(this.props.selectedGuest, position));
	}

	onSetActiveTab(tab) {
		this.setState({
			activeTab: tab
		});
	}

	onConnectToSelectedGuest() {
		if (!this.props.selectedGuest || !this.state.canConnectToGuest) {
			return;
		}
		//this.props.dispatch(connectToShowGuest(this.props.selectedGuest));
		this.props.dispatch(getShowGuestLock(this.props.selectedGuest));
	}

	onDisconnectSelectedGuest() {
		if (!this.props.remotePeerID) {
			return;
		}
		this.props.dispatch(disconnectShowGuest(this.props.selectedGuest, 'Incoming', 
			'CIM-UserId:' + this.props.UserId + '-Context:' + this.props.Context + '-PeerId:' + this.props.PeerId));
	}

	displayProperTabbedSection() {
		switch(this.state.activeTab[0]) {
		case 'Demographics': {
			return <GuestDemographicSection/>;
		}
		/*case 'CallerHistory': {
			return <CallerHistoryPresentation showHistory={this.props.showHistory}/>;
		}*/
		default:
			return null;
		}
	}

	componentWillReceiveProps(nextProps) {
		// This check makes no sense, must fix......
		if (!nextProps.selectedGuest.CookieGuid) {
			this.setState({
				canConnectToGuest: false,
				canMoveGuest: false
			});
		} else {
			if (nextProps.selectedGuest.Active) {
				if (
					nextProps.selectedGuest.CallState === 'Incoming' ||
					nextProps.selectedGuest.CallState === 'Finished'
				) {
					this.setState({
						canConnectToGuest: true,
						canMoveGuest: true
					});
				} else if (
					nextProps.selectedGuest.CallState === 'Active' ||
					nextProps.selectedGuest.CallState === 'OnAir' ||
					nextProps.selectedGuest.CallState === 'OffAir' ||
					nextProps.selectedGuest.CallState === 'Assigned'
				) {
					this.setState({
						canConnectToGuest: false,
						canMoveGuest: false
					});
				} else {
					this.setState({
						canConnectToGuest: false,
						canMoveGuest: true
					});
				}
			} else {
				this.setState({
					canConnectToGuest: false,
					canMoveGuest: true
				});
			}
		}
	}

	render() {
		return (
			<div className='ModifyGuestSection'>
				<div className='GuestMediaSection'>
					<GuestMediaSection/>
				</div>
				<div className='MoveGuestSection'>
					<MoveGuestPresentation
						connectToSelectedGuest={this.onConnectToSelectedGuest}
						disconnectSelectedGuest={this.onDisconnectSelectedGuest}
						remotePeerID={this.props.remotePeerID}
						selectedGuest={this.props.selectedGuest}
						onMoveSelectedGuest={this.onMoveSelectedGuest}
						canConnectToGuest={this.state.canConnectToGuest}
						canMoveGuest={this.state.canMoveGuest}
					/>
				</div>
				<div className='GuestDemographicsSection'>
					<GuestDemographicSection/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(ModifyGuestSection);