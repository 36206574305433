import React from 'react';
import { connect } from 'react-redux';
import { setSelectedGuest, getShowGuestDevices, SendObjectToShowGuest } from '../../../Redux/Actions/guestActions';
import './QueueTab.css';
import TabPresentation from '../TabPresentation';
import GuestQueuePresentation from './GuestQueue/GuestQueuePresentation';

function mapStateToProps(store) {
	return ({
		ClientGuid: store.user.ClientGuid,
		guests: {
			selectedGuest: store.show.selectedGuest.guest,
			guests: store.show.guests
		},
		currentTime: store.userinterface.currentTime
	});
}


class QueueTab extends React.Component {
	
	constructor(props) {
		super(props);

		this.state={
			activeTab: undefined
		};

		this.onSetActiveTab = this.onSetActiveTab.bind(this);
		this.onSetSelectedGuest = this.onSetSelectedGuest.bind(this);
		this.getTabPresentationInputList = this.getTabPresentationInputList.bind(this);
	}
	

	componentWillReceiveProps(nextProps) {
		if (nextProps.guests.selectedGuest.CookieGuid !== this.props.guests.selectedGuest.CookieGuid) {
			//this.props.dispatch(getShowGuestHistory(nextProps.guests.selectedGuest));
			this.props.dispatch(getShowGuestDevices(nextProps.guests.selectedGuest, nextProps.ClientGuid));
		}
		if (nextProps === this.props) {
			return;
		}
		if (this.state.activeTab) {
			return;
		}
		if (this.props.queues && this.props.queues.length >= 1) {
			this.setState({
				activeTab: this.props.queues[0]
			});
		}
	}

	onSetSelectedGuest(guest) {
		this.props.dispatch(setSelectedGuest(guest));
		this.props.dispatch(SendObjectToShowGuest(guest, {Message: 'Hello'}));
	}

	createGuestQueue(queueNameActual, queueNameFriendly, AdditionalActualQueues) {
		const guestList = this.props.guests.guests[queueNameActual];
		if (!guestList) {
			return null;
		}
		let selectedGuest;
		if (this.props.guests.selectedGuest && queueNameActual === this.props.guests.selectedGuest.CallState) {
			selectedGuest = this.props.guests.selectedGuest;
		} else if (AdditionalActualQueues && this.props.guests.selectedGuest) {
			AdditionalActualQueues.forEach(queueName => {
				if (this.props.guests.selectedGuest.CallState === queueName) {
					selectedGuest = this.props.guests.selectedGuest;
				}
			});
		}

		return (
			<GuestQueuePresentation
				guestList={guestList} 
				queueName={queueNameFriendly}
				onSetSelectedGuest={this.onSetSelectedGuest}
				selectedGuest={selectedGuest}
				currentTime={this.props.currentTime}
			/>
		);
	}
	onSetActiveTab(tab) {
		if (!tab) {
			return;
		}

		this.setState({
			activeTab: tab
		});
	}

	getTabPresentationInputList() {
		const guestQueues = [];
		this.props.queues.forEach(queueName => {
			if (queueName[0] && queueName[0][0] && this.props.guests.guests[queueName[0][0]]) {
				guestQueues.push([...queueName, this.props.guests.guests[queueName[0][0]].length]);
			}
		});
		return guestQueues;
	}

	render(){
		return(
			<div className='QueueTab'>
				<div className='QueueHeader'>
					<TabPresentation tabList={this.getTabPresentationInputList()} activeTab={this.state.activeTab} onSetActiveTab={this.onSetActiveTab}/>
				</div>
				<div className='QueueBody'>
					{(this.state.activeTab ? this.createGuestQueue(this.state.activeTab[0][0], this.state.activeTab[1], this.state.activeTab[0].slice(1)) : null)}
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(QueueTab);
